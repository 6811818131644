/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import ReactModal from 'react-modal';
import { func, arrayOf, object } from 'prop-types';
import cross from '../../assets/icons/cross.svg';
import ListSelector from '../ListSelector';
import Button from '../shared/Button';
import Input from '../shared/Input';
import './index.scss';

const GiftForModal = ({
  contacts,
  close,
  handleSubmitWithRecipient,
  handleSubmitNoRecipient,
}) => {
  const [isListDisplayed, setIsListDisplayed] = useState(false);
  const [giftRecipient, setGiftRecipient] = useState({});

  return (
    <ReactModal
      ariaHideApp={false}
      className="modal"
      overlayClassName="overlay"
      isOpen
    >
      <div className="gift-for-modal">
        <img src={cross} alt="" onClick={close} />
        <span className="gift-for-modal__title">Who is this gift for?</span>
        <Input
          className="gift-for-modal__input input--large"
          onClick={() => setIsListDisplayed(!isListDisplayed)}
          value={giftRecipient?.name}
          onChange={() => {}}
        />
        {isListDisplayed && (
          <ListSelector
            options={contacts}
            handleClick={(value) => {
              setGiftRecipient(value);
              setIsListDisplayed(false);
            }}
          />
        )}
        <Button
          className="btn--primary btn--x-large"
          disabled={isEmpty(giftRecipient)}
          onClick={() =>
            handleSubmitWithRecipient({
              giftRecipient,
            })
          }
        >
          CONTINUE
        </Button>
        <button
          onClick={handleSubmitNoRecipient}
          className="gift-for-modal__secondary-button"
          type="button"
        >
          Continue without recipient
        </button>
      </div>
    </ReactModal>
  );
};

GiftForModal.propTypes = {
  contacts: arrayOf(object),
  close: func.isRequired,
  handleSubmitWithRecipient: func.isRequired,
  handleSubmitNoRecipient: func.isRequired,
};

export default GiftForModal;
