import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { navigateTo } from '../../../helpers/historyHelper';
import { pronountToSegment } from '../../../helpers/parsers';
import './index.scss';

const CustomDay = ({ events, segments }) =>
  map(events, (item, index) => (
    <div key={`custom-day-${index}`} className="custom-day">
      <div className="custom-day__event">
        <span className="custom-day__event__user">
          {item.contact_name}&apos;s
        </span>
        <span className="custom-day__event__type">&nbsp; {item.date_name}</span>
      </div>
      <button
        className="custom-day__button"
        type="button"
        onClick={() =>
          navigateTo('/gifts', {
            segmentSelected: pronountToSegment(segments, item.contact_pronoun),
          })
        }
      >
        Explore Gifts
      </button>
    </div>
  ));

CustomDay.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
};
export default CustomDay;
