/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { func } from 'prop-types';
import cross from '../../assets/icons/cross.svg';
import blackStar from '../../assets/icons/blackStar.svg';
import Button from '../shared/Button';
import Input from '../shared/Input';
import SSOButton from '../shared/SSOButton';
import google from '../../assets/icons/google.svg';
import facebook from '../../assets/icons/facebook.svg';
import { useUser } from '../../context/UserContext';
import { validateEmail } from '../../helpers/validators';
import { isResponseSuccess } from '../../helpers/apiHelpers';
import './index.scss';

const SignInModal = ({ close, handleForgotPassword, handleGoToSignUp }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { login, error, googleSSO, facebookSSO } = useUser();

  const handleLogin = () => {
    if (!validateEmail(emailAddress)) {
      setErrorMessage('Enter a valid email address');
    } else {
      login({
        user: {
          email: emailAddress,
          password,
        },
      }).then((response) => {
        if (!isResponseSuccess(response))
          setErrorMessage('The email address or password is not correct');
      });
    }
  };

  const handleGoogle = (response) => {
    const auth = {
      accessToken: response?.accessToken,
      firstName: response?.profileObj?.givenName,
      lastName: response?.profileObj?.familyName,
    };
    googleSSO(auth, true);
  };

  const handleFacebook = (response) => {
    const auth = {
      accessToken: response?.accessToken,
      firstName: response?.first_name,
      lastName: response?.last_name,
    };
    facebookSSO(auth, true);
  };

  return (
    <ReactModal
      ariaHideApp={false}
      className="modal0"
      overlayClassName="overlay"
      isOpen
    >
      <div className="sign-in">
        <div className="sign-in__images">
          <img src={blackStar} alt="" className="sign-in__images__blackStar" />
          <img
            src={cross}
            alt=""
            className="sign-in__images__close"
            onClick={close}
          />
        </div>
        <span className="sign-in__title">Log in</span>
        <Input
          className="input--medium-large"
          label="Email address"
          type="email"
          placeholder="Email address"
          value={emailAddress}
          onChange={(event) => setEmailAddress(event.target.value)}
        />
        <Input
          className="input--medium-large"
          label="Password"
          errorMessage={errorMessage}
          error={errorMessage !== ''}
          placeholder="Min. 8 characters"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <button
          onClick={handleForgotPassword}
          className="sign-in__secondary-button"
          type="button"
        >
          Forgot your password?
        </button>

        <Button
          disabled={!(emailAddress && password && password.length >= 8)}
          className="btn--primary btn--large"
          onClick={handleLogin}
        >
          LOG IN
        </Button>
        <div className="sign-in__register">
          <span>Don&apos;t have an account?</span>
          <button
            onClick={handleGoToSignUp}
            className="sign-in__register__btn"
            type="button"
          >
            Register
          </button>
        </div>
        <div className="sign-in__error">{error && <span>{error}</span>}</div>
        <div className="sign-in__sso-section">
          <SSOButton
            isGoogle
            handleClick={handleGoogle}
            icon={google}
            text="Continue with Google"
          />
          <SSOButton
            handleClick={handleFacebook}
            icon={facebook}
            text="Continue with Facebook"
          />
        </div>
      </div>
    </ReactModal>
  );
};

SignInModal.propTypes = {
  close: func.isRequired,
  handleForgotPassword: func.isRequired,
  handleGoToSignUp: func.isRequired,
};

export default SignInModal;
