/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { string, func, bool } from 'prop-types';
import cross from '../../../assets/icons/cross.svg';
import google from '../../../assets/icons/google.svg';
import facebook from '../../../assets/icons/facebook.svg';
import blackStar from '../../../assets/icons/blackStar.svg';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import SSOButton from '../../shared/SSOButton';
import './index.scss';

const SignUpFirstStep = ({
  loading,
  emailAddress,
  setEmailAddress,
  handleContinue,
  handleGoToLogin,
  close,
  error,
  errorMessage,
  handleGoogle,
  handleFacebook,
}) => (
  <div className="sign-up-first">
    <div className="sign-up-first__images">
      <img
        src={blackStar}
        alt=""
        className="sign-up-first__images__blackStar"
      />
      <img
        src={cross}
        alt=""
        className="sign-up-first__images__close"
        onClick={close}
      />
    </div>
    <span className="sign-up-first__title">Register to givr</span>
    <div className="sign-up-first__button-section">
      <SSOButton
        isGoogle
        handleClick={handleGoogle}
        icon={google}
        text="Continue with Google"
      />
      <SSOButton
        handleClick={handleFacebook}
        icon={facebook}
        text="Continue with Facebook"
      />
    </div>
    <span className="sign-up-first__subtitle">
      Or enter with your email address
    </span>
    <Input
      className="input--medium-large"
      label="Email address"
      errorMessage={errorMessage}
      error={errorMessage !== ''}
      type="email"
      value={emailAddress}
      onChange={(event) => setEmailAddress(event.target.value)}
    />
    <Button
      disabled={!emailAddress || loading}
      className="btn--primary btn--large"
      onClick={() => handleContinue(emailAddress)}
    >
      CONTINUE
    </Button>
    {error && (
      <div className="sign-up-first__error">
        <span>{error}</span>
      </div>
    )}

    <button
      onClick={handleGoToLogin}
      className="sign-up-first__secondary-button"
      type="button"
    >
      Already have an account? Log in
    </button>
  </div>
);

SignUpFirstStep.propTypes = {
  loading: bool.isRequired,
  emailAddress: string.isRequired,
  setEmailAddress: func.isRequired,
  handleContinue: func.isRequired,
  handleGoToLogin: func.isRequired,
  close: func.isRequired,
  handleGoogle: func.isRequired,
  handleFacebook: func.isRequired,
  errorMessage: string.isRequired,
  error: string,
};

export default SignUpFirstStep;
