/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { func } from 'prop-types';
import cross from '../../assets/icons/cross.svg';
import blackStar from '../../assets/icons/blackStar.svg';
import leftArrow from '../../assets/icons/leftArrow.svg';
import Button from '../shared/Button';
import Input from '../shared/Input';
import './index.scss';
import { validateEmail } from '../../helpers/validators';
import styles from './modalStyles';
import { useUser } from '../../context/UserContext';

const ResetPasswordModal = ({ handleGoBack, close }) => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');

  const { forgotPassword } = useUser();

  const handleResetPassword = () => {
    setErrorMessage('');
    if (!validateEmail(email)) {
      // o no esta registrado
      setErrorMessage('Enter a valid email address');
    } else {
      forgotPassword(email)
        .then(() => {
          setStep(2);
        })
        .catch(() => {
          setErrorMessage(
            'The email address you have provided does not match any records in our database.'
          );
          setMessage('Please try again with a different email.');
        });
    }
  };

  const handleResendEmail = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  return (
    <ReactModal ariaHideApp={false} style={styles} isOpen>
      {step === 1 && (
        <div className="reset-password-first">
          <div className="reset-password-first__images">
            <img src={leftArrow} alt="" id="leftArrow" onClick={handleGoBack} />
            <img src={cross} alt="" id="close" onClick={close} />
          </div>
          <span className="reset-password-first__title">
            Reset your password
          </span>
          <span className="reset-password-first__subtitle">
            We’ll send an email with instructions to reset your password.
          </span>
          <Input
            className="input--medium-large"
            label="Email address"
            placeholder="Email address"
            type="email"
            errorMessage={errorMessage}
            error={errorMessage !== ''}
            successMessage={message}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Button
            disabled={!email}
            className="btn--primary btn--large"
            onClick={handleResetPassword}
          >
            RESET PASSWORD
          </Button>
        </div>
      )}
      {step === 2 && (
        <div className="reset-password-second">
          <div className="reset-password-second__images">
            <img src={blackStar} alt="" id="blackStar" />
            <img src={cross} alt="" id="close" onClick={close} />
          </div>
          <span className="reset-password-second__title">Check your email</span>
          <span className="reset-password-second__subtitle">
            We sent a link to <b id="black">{email}</b>
            <br />
            Follow the instructions to reset your password
          </span>
          <div className="reset-password-second__retry">
            <span>Did not receive the email?</span>
            <button onClick={handleResendEmail} type="button">
              Try again
            </button>
          </div>
        </div>
      )}
    </ReactModal>
  );
};

ResetPasswordModal.propTypes = {
  handleGoBack: func.isRequired,
  close: func.isRequired,
};

export default ResetPasswordModal;
