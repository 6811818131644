/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { string, func, bool, object } from 'prop-types';
import cross from '../../../assets/icons/cross.svg';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import './index.scss';

const SignUpSecondStep = ({
  loading,
  emailAddress,
  close,
  setEmailAddress,
  errors,
  handleCreateAccount,
}) => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [editingMail, setEditingMail] = useState(false);

  useEffect(() => {
    if (errors && errors.email) {
      setEditingMail(true);
    }
  }, [errors]);

  return (
    <div className="sign-up-second">
      <img
        src={cross}
        alt=""
        onClick={close}
        className="sign-up-second__close"
      />
      <span className="sign-up-second__title">Create your account</span>
      {!editingMail && (
        <div className="sign-up-second__email-section">
          <span className="sign-up-second__email-section__email">
            {emailAddress}
          </span>
          <button
            onClick={() => setEditingMail(true)}
            className="sign-up-second__email-section__link"
            type="button"
          >
            Change
          </button>
        </div>
      )}
      {editingMail && (
        <div className="sign-up-second__email-section-edit">
          <Input
            className="input--medium-large"
            label="Email address"
            type="email"
            placeholder="Email address"
            error={!!errors?.email}
            errorMessage={errors?.email}
            value={emailAddress}
            onChange={(event) => setEmailAddress(event.target.value)}
          />
          <button
            onClick={() => setEditingMail(false)}
            className="sign-up-second__email-section-edit__link"
            type="button"
          >
            Done
          </button>
        </div>
      )}
      <Input
        className="input--medium-large"
        label="First Name"
        type="text"
        placeholder="First Name"
        value={name}
        error={!!errors?.first_name}
        errorMessage={errors?.first_name}
        onChange={(event) => setName(event.target.value)}
      />
      <Input
        className="input--medium-large"
        label="Last Name"
        type="text"
        placeholder="Last Name"
        error={!!errors?.last_name}
        errorMessage={errors?.last_name}
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
      />
      <Input
        className="input--medium-large"
        label="Password"
        type="password"
        placeholder="Min. 8 characters"
        error={!!errors?.password}
        errorMessage={errors?.password}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
      <Button
        disabled={
          !(emailAddress && password && password.length >= 8 && !editingMail) ||
          loading
        }
        className="btn--primary btn--large"
        onClick={() => handleCreateAccount(name, lastName, password)}
      >
        CREATE ACCOUNT
      </Button>
      <div className="sign-up-second__footer">
        By registering you accept to givr{' '}
        <span>
          <Link to="/terms" target="_blank">
            Terms and Conditions
          </Link>
        </span>{' '}
        and{' '}
        <span>
          <Link to="/privacy" target="_blank">
            Privacy Policy
          </Link>
        </span>
        .
      </div>
    </div>
  );
};

SignUpSecondStep.propTypes = {
  loading: bool.isRequired,
  emailAddress: string.isRequired,
  close: func.isRequired,
  handleCreateAccount: func.isRequired,
  setEmailAddress: func.isRequired,
  errors: object,
};

export default SignUpSecondStep;
