/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { loggedIn } = useUser();

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...props}
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to="/homeDev" />
      }
    />
  );
};

export default PrivateRoute;
