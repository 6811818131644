import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import './index.scss';

const MissionTypeFilter = ({ list, missionSelected, setMissionSelected }) => (
  <div className="mission-type-filter">
    <span className="mission-type-filter__title">Mission Type</span>
    {map(list, (data, index) => (
      <button
        key={`mission-${index}`}
        className={`mission-type-filter__filter ${
          (missionSelected?.id === data?.id &&
            'mission-type-filter__filter--selected') ||
          ''
        }`}
        type="button"
        onClick={() => setMissionSelected(data)}
      >
        {data.name}
      </button>
    ))}
  </div>
);

MissionTypeFilter.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  missionSelected: PropTypes.object,
  setMissionSelected: PropTypes.func,
};

export default MissionTypeFilter;
