import React from 'react';
import PropTypes from 'prop-types';
import cross from '../../../assets/icons/cross.svg';
import blackStar from '../../../assets/icons/blackStar.svg';
import facebook from '../../../assets/icons/facebookRounded.svg';
import twitter from '../../../assets/icons/twitterRounded.svg';
import whatsapp from '../../../assets/icons/whatsappRounded.svg';
import './index.scss';

const SecondStep = ({ close, url }) => (
  <div className="second-step">
    <div className="second-step__header">
      <img src={blackStar} alt="" className="second-step__header__blackStar" />
      <span className="second-step__header__title">Thanks you</span>
    </div>
    <div className="second-step__close" onClick={close}>
      <img src={cross} alt="" />
    </div>
    <span className="second-step__subtitle">
      Click below to share your referral link.
    </span>
    <div className="second-step__social">
      <div className="second-step__social__container">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.facebook.com/sharer.php?u=${url}`}
        >
          <img
            className="second-step__social__container__img"
            src={facebook}
            alt=""
          />
        </a>
      </div>
      <div className="second-step__social__container">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://twitter.com/intent/tweet?url=${url}&text=Sign%20up%20for%20Givr%21`}
        >
          <img
            className="second-step__social__container__img"
            src={twitter}
            alt=""
          />
        </a>
      </div>
      <div className="second-step__social__container">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://api.whatsapp.com/send?text=Sign%20up%20for%20Givr%21%20${url}`}
          data-action="share/whatsapp/share"
        >
          <img
            className="second-step__social__container__img"
            src={whatsapp}
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
);

SecondStep.propTypes = {
  close: PropTypes.func,
  url: PropTypes.string,
};

export default SecondStep;
