import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const MerchantFilter = ({ merchant, setMerchant }) => (
  <div className="merchant-filter">
    <span className="merchant-filter__title">Merchant</span>
    <input
      value={merchant}
      onChange={(event) => setMerchant(event.target.value)}
      placeholder="Filter by Merchant"
      className="merchant-filter__input"
    />
  </div>
);
MerchantFilter.propTypes = {
  merchant: PropTypes.string,
  setMerchant: PropTypes.func,
};

export default MerchantFilter;
