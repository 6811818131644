/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { func } from 'prop-types';
import snakecaseKeys from 'snakecase-keys';
import SignUpFirstStep from './FirstStep';
import SignUpSecondStep from './SecondStep';
import { validateEmail } from '../../helpers/validators';
import { useUser } from '../../context/UserContext';
import './index.scss';

const SignUpModal = ({ close, handleGoToLogin }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(1);

  const { signUp, googleSSO, loading, setError, error, errors, facebookSSO } =
    useUser();

  const handleContinue = () => {
    if (!validateEmail(emailAddress)) {
      setErrorMessage('Enter a valid email address');
    } else {
      setStep(2);
    }
  };
  const handleCreateAccount = (firstName, lastName, password) => {
    signUp(
      snakecaseKeys({
        user: {
          username: emailAddress,
          email: emailAddress,
          firstName,
          lastName,
          password,
          confirmPassword: password,
        },
      })
    );
  };

  const handleGoogle = (response) => {
    const auth = {
      accessToken: response?.accessToken,
      firstName: response?.profileObj?.givenName,
      lastName: response?.profileObj?.familyName,
    };
    googleSSO(auth, false);
  };

  const handleFacebook = (response) => {
    const auth = {
      accessToken: response?.accessToken,
      firstName: response?.first_name,
      lastName: response?.last_name,
    };
    facebookSSO(auth, false);
  };

  return (
    <ReactModal
      ariaHideApp={false}
      className="modalo"
      overlayClassName="overlay"
      isOpen
    >
      {step === 1 && (
        <SignUpFirstStep
          close={close}
          setError={setError}
          loading={loading}
          handleContinue={handleContinue}
          handleGoToLogin={handleGoToLogin}
          emailAddress={emailAddress}
          error={error}
          errorMessage={errorMessage}
          setEmailAddress={setEmailAddress}
          handleGoogle={handleGoogle}
          handleFacebook={handleFacebook}
        />
      )}
      {step === 2 && (
        <SignUpSecondStep
          loading={loading}
          close={close}
          emailAddress={emailAddress}
          errors={errors}
          setEmailAddress={setEmailAddress}
          handleCreateAccount={handleCreateAccount}
        />
      )}
      {error && (
        <div className="sign-up-first__error">
          <span>{error}</span>
        </div>
      )}
    </ReactModal>
  );
};

SignUpModal.propTypes = {
  close: func.isRequired,
  handleGoToLogin: func.isRequired,
};

export default SignUpModal;
