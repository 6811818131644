import React from 'react';
import cn from 'classnames';
import { string, number, func, object } from 'prop-types';
import './index.scss';

const ProductCard = ({ title, price, merchant, image, onClick, className }) => (
  <div className={cn('product-card', className)} onClick={onClick}>
    <div className="product-card__image">
      <img alt="Product" src={image} />
    </div>
    <div className="product-card__info">
      <div className="product-card__info__container">
        <span className="product-card__info__container__title">{title}</span>
        <span className="product-card__info__container__price">${price}</span>
      </div>
      <div className="product-card__info__merchant">
        <span className="product-card__info__merchant__title">
          {merchant?.name}
        </span>
      </div>
    </div>
  </div>
);

ProductCard.propTypes = {
  title: string.isRequired,
  price: number.isRequired,
  merchant: object.isRequired,
  image: string.isRequired,
  className: string.isRequired,
  onClick: func,
};

export default ProductCard;
