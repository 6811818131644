import React from 'react';
import { string, func, bool } from 'prop-types';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import './index.scss';

const SSOButton = ({ isGoogle = false, icon, text, handleClick }) => {
  if (isGoogle)
    return (
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_SSO}
        render={(renderProps) => (
          <div className="sso-button" onClick={renderProps.onClick}>
            <img src={icon} alt="" />
            <span>{text}</span>
          </div>
        )}
        buttonText="Login"
        onSuccess={(response) => handleClick(response)}
        cookiePolicy="single_host_origin"
      />
    );
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_SSO}
      callback={(response) => handleClick(response)}
      fields="id,first_name,last_name"
      autoLoad={false}
      render={(renderProps) => (
        <div className="sso-button" onClick={renderProps.onClick}>
          <img src={icon} alt="" />
          <span>{text}</span>
        </div>
      )}
    />
  );
};

SSOButton.propTypes = {
  isGoogle: bool,
  icon: string.isRequired,
  text: string.isRequired,
  handleClick: func.isRequired,
};

export default SSOButton;
