import React from 'react';
import { func, string, bool } from 'prop-types';
import searchIconWhite from '../../../assets/icons/searchIcon.svg';
import searchIcon from '../../../assets/icons/searchIconPrimary.svg';
import './index.scss';

const HeaderInput = ({ isDark, onChange, placeholder, value }) => (
  <div className={`header-input ${isDark ? 'header-input--dark' : ''}`}>
    <input placeholder={placeholder} onChange={onChange} value={value} />
    <img src={isDark ? searchIconWhite : searchIcon} alt="" />
  </div>
);

HeaderInput.propTypes = {
  onChange: func.isRequired,
  placeholder: string,
  value: string,
  isDark: bool,
};

export default HeaderInput;
