import React from 'react';
import { bool, string, func } from 'prop-types';
import './index.scss';

const TabButton = ({ isSelected, text, onClick }) => (
  <button
    onClick={onClick}
    type="button"
    className={isSelected ? 'tab-item-selected' : 'tab-item'}
  >
    {text}
  </button>
);

TabButton.propTypes = {
  isSelected: bool.isRequired,
  text: string.isRequired,
  onClick: func,
};

export default TabButton;
