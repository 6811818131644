/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { func } from 'prop-types';
import Input from '../../shared/Input';
import { validateMMDDYY } from '../../../helpers/validators';
import checkGreenIcon from '../../../assets/icons/checkGreen.svg';
import closeRedIcon from '../../../assets/icons/closeRed.svg';
import './index.scss';

// eslint-disable-next-line
const CustomDate = ({ handleAdd, handleCancel }) => {
  const [dateName, setDateName] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorTime, setErrorTime] = useState('');

  const validateKey = (e) => {
    let keynum;
    if (window.event) {
      // IE
      keynum = e.keyCode;
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which;
    }
    if (
      (dateTime.length === 2 || dateTime.length === 5) &&
      (!dateTime.match('/') ||
        (dateTime.match('/') && dateTime.length === 5)) &&
      !(keynum === 8)
    ) {
      setDateTime(`${dateTime}/`);
    }
  };

  const handleDone = () => {
    setErrorName('');
    setErrorTime('');
    if (validateMMDDYY(dateTime) && dateName !== '') {
      handleAdd(dateName, dateTime);
    } else {
      if (dateName === '') setErrorName(`Name can't be blank`);
      if (!validateMMDDYY(dateTime)) setErrorTime('Invalid format');
    }
  };

  return (
    <div className="custom-date">
      <div className="custom-date__date">
        <Input
          className="input--small"
          type="text"
          placeholder="Eg. First date"
          error={errorName !== ''}
          errorMessage={errorName}
          label="Event name"
          value={dateName}
          onChange={(event) => setDateName(event.target.value)}
          maxLength={10}
        />
        <Input
          className="input--small"
          type="text"
          placeholder="MM/DD/YYYY"
          error={errorTime !== ''}
          errorMessage={errorTime}
          label="Date"
          value={dateTime}
          onChange={(event) => setDateTime(event.target.value)}
          maxLength={10}
          onKeyDown={validateKey}
        />
        <button
          type="button"
          className={`custom-date__date__button ${
            (errorName || errorTime) && 'custom-date__date__button--error'
          }`}
          onClick={handleDone}
        >
          <img
            className="custom-date__date__button__icon"
            src={checkGreenIcon}
            alt=""
          />
        </button>
        <button
          type="button"
          className={`custom-date__date__button ${
            (errorName || errorTime) && 'custom-date__date__button--error'
          }`}
          onClick={handleCancel}
        >
          <img
            className="custom-date__date__button__icon"
            src={closeRedIcon}
            alt=""
          />
        </button>
      </div>
    </div>
  );
};
CustomDate.propTypes = {
  handleAdd: func.isRequired,
  handleCancel: func.isRequired,
};

export default CustomDate;
