import React from 'react';
import aboutGivr from '../../assets/images/aboutGivr/aboutGivr.png';
import aboutGivrMobile from '../../assets/images/aboutGivr/aboutGivrMobile.svg';
import './index.scss';

const AboutGivr = () => (
  <div className="about-givr">
    <img className="about-givr__image" alt="" src={aboutGivr} />
    <img className="about-givr__image-mobile" alt="" src={aboutGivrMobile} />
    <div className="about-givr__text">
      <span className="about-givr__text__subtitle">
        About <b>givr</b>
      </span>
      <span className="about-givr__text__title">
        Unique gifts from mission driven companies
      </span>
      <span className="about-givr__text__body">
        Givr is a technology driven gift giving platform designed to save users
        the headache and hassle of finding the perfect gift by serving
        personalized gift recommendations, specific to each recipient, from
        companies making a social impact.
      </span>
      <span className="about-givr__text__title">Why?</span>
      <span className="about-givr__text__body">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
        phasellus quisque risus ut posuere vel eros erat. Tincidunt lectus nunc
        arcu orci nibh quam id interdum vel.
      </span>
    </div>
  </div>
);

export default AboutGivr;
