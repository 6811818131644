import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import './index.scss';

const ClientTypeFilter = ({ list, segmentSelected, setSegmentSelected }) => (
  <div className="client-filter">
    <div className="client-filter__container">
      {map(list, (data, index) => (
        <button
          key={`button-${index}`}
          className={`client-filter__container__filter ${
            (segmentSelected?.id === data.id &&
              'client-filter__container__filter--selected') ||
            ''
          }`}
          type="button"
          onClick={() => setSegmentSelected(data)}
        >
          {data.name}
        </button>
      ))}
    </div>
  </div>
);

ClientTypeFilter.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  segmentSelected: PropTypes.object,
  setSegmentSelected: PropTypes.func,
};

export default ClientTypeFilter;
