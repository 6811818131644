import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import leftArrow from '../../assets/icons/leftArrow.svg';
import TabButton from '../TabButton';
import './index.scss';

const Tabs = ({ list, categorySelected = null, handleSelectCategory }) => {
  const tabRef = useRef();
  const [maxWidth, setMaxWidth] = useState(-1);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    let width = tabRef?.current?.scrollWidth;
    if (tabRef?.current?.offsetWidth) {
      width -= tabRef?.current?.offsetWidth;
    }
    setMaxWidth(width);
  }, [tabRef, list]);

  const handleSlide = (right) => {
    let newScroll = 0;
    if ((scroll >= maxWidth && right) || (scroll === 0 && !right)) {
      return;
    }
    if (right) {
      newScroll =
        scroll + 300 > maxWidth ? scroll + (maxWidth % 300) : scroll + 300;
    } else {
      newScroll = scroll - 300 > 0 ? scroll - 300 : scroll - (maxWidth % 300);
    }
    tabRef?.current?.scrollTo({
      top: 0,
      left: newScroll,
      behavior: 'smooth',
    });
    setScroll(newScroll);
  };

  return (
    <div className="tabs">
      <div className="tabs__arrow" onClick={() => handleSlide(false)}>
        {list && list.length > 0 && <img alt="Left" src={leftArrow} />}
      </div>
      <div ref={tabRef} className="tabs__content">
        {map(list, (date, index) => (
          <TabButton
            key={`category-${index}`}
            onClick={() => handleSelectCategory(date)}
            isSelected={categorySelected?.id === date?.id}
            text={date?.name}
          />
        ))}
      </div>

      <div
        className="tabs__arrow tabs__arrow--right"
        onClick={() => handleSlide(true)}
      >
        {list && list.length > 0 && <img alt="Right" src={leftArrow} />}
      </div>
    </div>
  );
};
Tabs.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  handleSelectCategory: PropTypes.func,
  categorySelected: PropTypes.object,
};

export default Tabs;
