import { find } from 'lodash';

export const pronountToSegment = (segments, pronoun) => {
  switch (pronoun) {
    case 'She/Her':
      return find(segments, (item) => item.name === 'For Her');
    case 'He/Him':
      return find(segments, (item) => item.name === 'For Him');
    default:
      return null;
  }
};
