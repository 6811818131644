/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, createContext, useContext, useEffect } from 'react';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { handleError } from '../../helpers/apiHelpers';
import { setAuthInfo, isLoggedIn, cleanAuthInfo } from '../../helpers/session';
import * as apiService from '../../api';

const UserContext = createContext({
  user: null,
  defaultEvents: {},
  error: '',
  errors: null,
  loggedIn: false,
  loading: true,
  upcomingEvents: {},
  allContacts: [],
  showAddContactModal: false,
  showReferralModal: false,
  login: () => {},
  googleSSO: () => {},
  facebookSSO: () => {},
  setOpenSignInModal: () => {},
  setLoggedIn: () => {},
  getProfile: () => {},
  signUp: () => {},
  forgotPassword: () => {},
  resetPassword: () => {},
  logout: () => {},
  setError: () => {},
  addContact: () => {},
  getUpcomingEvents: () => {},
  getAllContacts: () => {},
  fetchEvents: () => {},
  setShowAddContactModal: () => {},
  setShowReferralModal: () => {},
});

function UserProvider(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [defaultEvents, setDefaultEvents] = useState({});
  const [upcomingEvents, setUpcomingEvents] = useState({});
  const [allContacts, setAllContacts] = useState([]);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const hasToken = await isLoggedIn();
        if (hasToken) {
          const userProfile = await getProfile();
          if (userProfile) {
            await getDefaultEvents();
          }
          setLoggedIn(!!userProfile);
        } else {
          setLoggedIn(false);
        }
      } catch (error) {
        await cleanAuthInfo();
        setLoggedIn(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      fetchEvents();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
        setErrors(null);
      }, 4000);
    }
  }, [error, errors]);

  const login = async (user) => {
    setLoading(true);
    return apiService.post(`/users/sign_in`, user).then(
      async (response) => {
        const { data } = response;
        await setAuthInfo(
          response.headers['access-token'],
          response.headers.client,
          response.headers.uid
        );
        window &&
          window.identifyDrip(
            data.user.email,
            data.user.first_name,
            data.user.last_name
          );
        setUser(camelcaseKeys(data.user));
        setLoading(false);
        setLoggedIn(true);
        setOpenSignInModal(false);
      },
      (error) => {
        setLoading(false);
        setError(error.response?.data?.error);
        setErrors(error.response?.data?.errors);
      }
    );
  };

  const signUp = (user) => {
    setLoading(true);
    return apiService.post(`/users`, user).then(
      async (response) => {
        const { data } = response;
        setLoading(false);
        await setAuthInfo(
          response.headers['access-token'],
          response.headers.client,
          response.headers.uid
        );
        window &&
          window.identifyDrip(
            data.user.email,
            data.user.first_name,
            data.user.last_name
          );
        setUser(camelcaseKeys(data.user));
        setLoading(false);
        setLoggedIn(true);
        setOpenSignUpModal(false);
        setShowAddContactModal(true);
        return response.data;
      },
      (error) => {
        setLoading(false);
        setError(error.response.data.error);
        setErrors(error.response.data.errors);
        throw error;
      }
    );
  };

  const forgotPassword = (email) => {
    setLoading(true);
    return apiService
      .post(`/users/password`, {
        email,
      })
      .then(
        (response) => {
          setLoading(false);
          return response.data;
        },
        (error) => {
          setLoading(false);
          setError(error.response.data.error);
          setErrors(error.response.data.errors);
          throw error;
        }
      );
  };

  const resetPassword = (data) => {
    setLoading(true);
    return apiService.put(`/users/password`, data).then(
      (response) => {
        setLoading(false);
        return response.data;
      },
      (error) => {
        setLoading(false);
        setError(error.response.data.error);
        setErrors(error.response.data.errors);
        throw error;
      }
    );
  };

  const logout = async () => {
    await cleanAuthInfo();
    setUser(null);
    setAllContacts([]);
    setUpcomingEvents({});
    setLoggedIn(false);
  };

  const getProfile = () => {
    setLoading(true);
    return apiService
      .get(`/user`)
      .then(
        (response) => {
          const { data } = response;
          setUser(camelcaseKeys(data.user));
          return data;
        },
        (error) => {
          handleError(setLoggedIn, setError, error);
          setError(error.response.data.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const googleSSO = (auth, isLogin) => {
    setLoading(true);
    return apiService
      .post(`/users/google`, snakecaseKeys(auth))
      .then(
        async (response) => {
          const { data } = response;
          await setAuthInfo(
            response.headers['access-token'],
            response.headers.client,
            response.headers.uid
          );
          window?.identifyDrip(
            data.user.email,
            data.user.first_name,
            data.user.last_name
          );
          setUser(camelcaseKeys(data.user));
          setLoggedIn(true);
          setOpenSignUpModal(false);
          setOpenSignInModal(false);
          if (!isLogin) setShowAddContactModal(true);
          return data;
        },
        (error) => {
          setError(error.response?.data?.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const facebookSSO = (auth, isLogin) => {
    setLoading(true);
    return apiService
      .post(`/users/facebook`, snakecaseKeys(auth))
      .then(
        async (response) => {
          const { data } = response;
          await setAuthInfo(
            response.headers['access-token'],
            response.headers.client,
            response.headers.uid
          );
          window?.identifyDrip(
            data.user.email,
            data.user.first_name,
            data.user.last_name
          );
          setUser(camelcaseKeys(data.user));
          setLoggedIn(true);
          setOpenSignUpModal(false);
          setOpenSignInModal(false);
          if (!isLogin) setShowAddContactModal(true);
          return data;
        },
        (error) => {
          setError(error?.response?.data?.error);
        }
      )
      .finally(() => setLoading(false));
  };

  const addContact = (data) => {
    setLoading(true);
    return apiService
      .post(`/contacts`, data)
      .then(
        (response) => response.data,
        (error) => {
          handleError(setLoggedIn, setError, error);
          setError(error.response?.data?.error);
          throw error;
        }
      )
      .finally(() => setLoading(false));
  };

  const getUpcomingEvents = () => {
    setLoading(true);
    return apiService
      .get('/contacts/upcoming_dates')
      .then((response) => response?.data)
      .catch((error) => {
        handleError(setLoggedIn, setError, error);
        setError(error?.response?.data?.error);
      })
      .finally(() => setLoading(false));
  };

  const getAllContacts = () => {
    setLoading(true);
    return apiService
      .get('/contacts')
      .then((response) => response?.data)
      .catch((error) => {
        handleError(setLoggedIn, setError, error);
        setError(error?.response?.data?.error);
      })
      .finally(() => setLoading(false));
  };

  const getDefaultEvents = () => {
    setLoading(true);
    return apiService
      .get('/contacts/default_events_for_contact')
      .then((response) => {
        setDefaultEvents(response?.data);
      })
      .catch((error) => {
        handleError(setLoggedIn, setError, error);
        setError(error?.response?.data?.error);
      })
      .finally(() => setLoading(false));
  };

  const fetchEvents = () => {
    getUpcomingEvents().then((response) => {
      setUpcomingEvents(response || []);
    });
    getAllContacts().then((response) => {
      setAllContacts(response?.contacts);
    });
  };

  return (
    <UserContext.Provider
      value={{
        loading,
        error,
        user,
        defaultEvents,
        errors,
        loggedIn,
        openSignUpModal,
        openSignInModal,
        openResetPasswordModal,
        upcomingEvents,
        allContacts,
        showAddContactModal,
        showReferralModal,
        googleSSO,
        facebookSSO,
        setOpenSignUpModal,
        setOpenSignInModal,
        setOpenResetPasswordModal,
        getProfile,
        setLoggedIn,
        login,
        signUp,
        forgotPassword,
        resetPassword,
        logout,
        setError,
        setLoading,
        addContact,
        getUpcomingEvents,
        getAllContacts,
        fetchEvents,
        setShowAddContactModal,
        setShowReferralModal,
      }}
      {...props}
    />
  );
}

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
