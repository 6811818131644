import React from 'react';
import Header from '../Header';
import Banner from '../Banner';
import productExampleGreenBanner from '../../assets/images/banner/productExampleGreenBanner.svg';

const TopBar = () => (
  <div className="top-bar-container">
    <Header isDark />
    <Banner image={productExampleGreenBanner} />
  </div>
);

export default TopBar;
