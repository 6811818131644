/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import queryString from 'query-string';
import camelCaseKeys from 'camelcase-keys';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../context/UserContext';

import Button from '../../components/shared/Button';
import Input from '../../components/shared/Input';
import givrLogoPrimary from '../../assets/images/givrLogoPrimary.svg';
import blackStar from '../../assets/icons/blackStar.svg';

import './index.scss';
import { navigateTo } from '../../helpers/historyHelper';
import { setAuthInfo } from '../../helpers/session';
import { isResponseSuccess } from '../../helpers/apiHelpers';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(0);
  const location = useLocation();
  const { resetPassword } = useUser();

  const { accessToken, client, uid } = camelCaseKeys(
    queryString?.parse(location.search)
  );

  setAuthInfo(accessToken, client, uid);

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (confirmPassword === password) {
      const data = {
        password_value: password,
        password_confirmation_value: confirmPassword,
      };
      resetPassword(data)
        .then((response) => {
          if (isResponseSuccess(response)) {
            navigateTo('/');
          }
        })
        .catch((errors) => {
          console.error(errors);
        });
      setStep(1);
    }
  };
  return (
    <div className="reset-password">
      <div className="reset-password__header">
        <img
          src={givrLogoPrimary}
          alt=""
          onClick={() => navigateTo('/homeDev')}
        />
      </div>
      <div className="reset-password__form">
        <>
          {step === 0 && (
            <>
              <span className="reset-password__form__title">
                Create new password
              </span>
              <Input
                className="input--medium-large"
                placeholder="Min. 8 characters"
                type="password"
                label="New password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Input
                className="input--medium-large"
                placeholder="Min. 8 characters"
                type="password"
                label="Confirm new password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
              <Button
                className="btn--primary btn--large"
                onClick={handleResetPassword}
                disabled={
                  !(
                    password &&
                    password.length >= 8 &&
                    confirmPassword &&
                    confirmPassword.length >= 8
                  )
                }
              >
                SAVE NEW PASSWORD
              </Button>
            </>
          )}
          {step === 1 && (
            <>
              <img
                src={blackStar}
                alt=""
                className="reset-password__form__star"
              />
              <span className="reset-password__form__title">Success!</span>
              <span className="reset-password__form__subtitle">
                Your new password has been
                <br />
                successfully created
              </span>
              <Button
                className="btn--primary btn--large"
                onClick={() => navigateTo('/homeDev')}
              >
                LOGIN
              </Button>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default ResetPassword;
