/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import { bool, string, object, func } from 'prop-types';
import noNotifications from '../../assets/icons/noNotifications.svg';
import creamNoNotifications from '../../assets/icons/creamNoNotifications.svg';
import megaphone from '../../assets/icons/megaphone.svg';
import megaphoneWhite from '../../assets/icons/megaphoneWhite.svg';
import creamUser from '../../assets/icons/creamUser.svg';
import notificationCream from '../../assets/icons/notificationCream.svg';
import notification from '../../assets/icons/notification.svg';
import user from '../../assets/icons/user.svg';
import { navigateTo } from '../../helpers/historyHelper';
import NotificationDropdown from './NotificationDropdown';

import './index.scss';

const NotifiactionsSection = ({
  hasNotifications,
  name,
  isDark,
  notifications,
  showReferralModal,
}) => {
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const ref = useRef();

  return (
    <div className="notifiactions-section">
      <div
        className="notifiactions-section__referral"
        onClick={() => showReferralModal()}
      >
        {isDark ? (
          <img alt="" src={megaphone} />
        ) : (
          <img alt="" src={megaphoneWhite} />
        )}
      </div>
      {isOpenNotification && (
        <NotificationDropdown
          bellRef={ref}
          notifications={notifications}
          close={() => setIsOpenNotification(false)}
        />
      )}
      {!hasNotifications && (
        <img
          className="notifiactions-section__notification"
          src={isDark ? creamNoNotifications : noNotifications}
          alt=""
          id="notif"
          ref={ref}
          onClick={() => setIsOpenNotification(!isOpenNotification)}
        />
      )}
      {hasNotifications && (
        <img
          className="notifiactions-section__notification"
          src={isDark ? notificationCream : notification}
          alt=""
          id="notif"
          ref={ref}
          onClick={() => setIsOpenNotification(!isOpenNotification)}
        />
      )}
      <img
        onClick={() => navigateTo('/profile')}
        className="notifiactions-section__user"
        src={isDark ? creamUser : user}
        alt=""
      />
      <span
        onClick={() => navigateTo('/profile')}
        className={
          isDark
            ? 'notifiactions-section__user-name-dark'
            : 'notifiactions-section__user-name'
        }
      >
        {name}
      </span>
    </div>
  );
};
NotifiactionsSection.propTypes = {
  notifications: object,
  hasNotifications: bool.isRequired,
  name: string.isRequired,
  isDark: bool,
  showReferralModal: func,
};

export default NotifiactionsSection;
