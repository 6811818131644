/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { map } from 'lodash';
import { string, func, arrayOf, object } from 'prop-types';
import CustomDate from './CustomDate';
import trashIcon from '../../assets/icons/trash.svg';
import './index.scss';

const CheckBoxList = ({
  fixedDatesSelected,
  setFixedDatesSelected,
  variableDatesSelected,
  setVariablesDatesSelected,
  customDatesSelected,
  setCustomDatesSelected,
}) => {
  const [editing, setEditing] = useState(false);

  const handleRemoveCustomDate = (id) => {
    let count = 0;
    const newCustomDates = [];
    customDatesSelected.forEach((item) => {
      if (item.id !== id) {
        newCustomDates.push({ ...item, id: count });
        count += 1;
      }
    });
    setCustomDatesSelected(newCustomDates);
  };

  const handleConfirmAddCustomDate = (name, date) => {
    const newCustomDates = [
      ...customDatesSelected,
      {
        id: customDatesSelected.length + 1,
        name,
        date,
        selected: true,
      },
    ];
    setCustomDatesSelected(newCustomDates);
    setEditing(false);
  };

  const validateKey = (e) => {
    let keynum;
    if (window.event) {
      // IE
      keynum = e.keyCode;
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which;
    }
    const word = e.target.value;
    if (
      (word.length === 2 || word.length === 5) &&
      (!word.match('/') || (word.match('/') && word.length === 5)) &&
      !(keynum === 8)
    ) {
      e.target.value = `${word}/`;
    }
  };

  const handleFixedDatesCheckboxChange = (event) => {
    const newDatesSelected = fixedDatesSelected.map((element) => {
      if (element.id == event.target.value) {
        return {
          ...element,
          selected: !element.selected,
        };
      }
      return element;
    });
    setFixedDatesSelected(newDatesSelected);
  };

  const handleCustomCheckboxChange = (event) => {
    const newCustomDates = customDatesSelected.map((element) => {
      if (element.id == event.target.value) {
        return {
          ...element,
          selected: !element.selected,
        };
      }
    });
    setCustomDatesSelected(newCustomDates);
  };

  const handleVariableDatesCheckboxChange = (event) => {
    const newDatesSelected = variableDatesSelected.map((element) => {
      if (element.id == event.target.value) {
        return {
          ...element,
          selected: !element.selected,
        };
      }
      return element;
    });
    setVariablesDatesSelected(newDatesSelected);
  };

  const handleVariableInputChange = (e) => {
    const newDatesChanged = variableDatesSelected.map((elem) => {
      if (elem.id == e.target.id) {
        return {
          ...elem,
          date: e.target.value,
        };
      }
      return elem;
    });
    setVariablesDatesSelected(newDatesChanged);
  };

  return (
    <div className="checkbox">
      <span className="checkbox__label">Important Dates to be reminded of</span>
      <div className="checkbox__list">
        {map(fixedDatesSelected, (item, index) => (
          <div key={`date-fixed-${index}`} className="checkbox__list__item">
            <input
              type="checkbox"
              id={`checkbox-fixed-${index}`}
              name={item.name}
              value={item.id}
              checked={item.selected}
              onChange={handleFixedDatesCheckboxChange}
              className="checkbox__list__item__checkbox"
            />
            <label htmlFor={`checkbox-fixed-${index}`} />
            <span className="checkbox__list__item__value">{item.name}</span>
          </div>
        ))}
        {map(variableDatesSelected, (item, index) => (
          <div
            key={`date-variable-${index}`}
            className="checkbox__list__item--variable"
          >
            <div className="checkbox__list__item checkbox__list__item--variable__container">
              <input
                type="checkbox"
                id={`checkbox-variable-${index}`}
                name={item.name}
                value={item.id}
                checked={item.selected}
                onChange={handleVariableDatesCheckboxChange}
                className="checkbox__list__item__checkbox"
              />
              <label htmlFor={`checkbox-variable-${index}`} />
              <span className="checkbox__list__item__value">{item.name}</span>
            </div>
            {item.selected && (
              <div className="checkbox__list__item__variable">
                <input
                  type="text"
                  maxLength="10"
                  className="checkbox__list__item__variable__input-text"
                  placeholder="MM/DD/YYYY"
                  id={item.id}
                  value={item.date}
                  onChange={handleVariableInputChange}
                  onKeyDown={validateKey}
                />
                {item.error && (
                  <span className="checkbox__list__item__variable__error">
                    {item.error}
                  </span>
                )}
              </div>
            )}
          </div>
        ))}
        {customDatesSelected.map((date, key) => (
          <div key={`custom-date-${key}`} className="checkbox__list__item">
            <input
              id={`checkbox-custom-${date.id}`}
              type="checkbox"
              name={date.type}
              value={date.id}
              checked={date.selected}
              onChange={handleCustomCheckboxChange}
              className="checkbox__list__item__checkbox"
            />
            <label htmlFor={`checkbox-custom-${date.id}`} />
            <span className="checkbox__list__item__value">{`${date.name} - ${date.date}`}</span>
            <button
              type="button"
              className="checkbox__list__item__button"
              onClick={() => handleRemoveCustomDate(date.id)}
            >
              <img
                className="checkbox__list__item__button__icon"
                src={trashIcon}
                alt=""
              />
            </button>
          </div>
        ))}
        {editing && (
          <CustomDate
            handleAdd={handleConfirmAddCustomDate}
            handleCancel={() => setEditing(false)}
          />
        )}
      </div>
      {!editing && (
        <button
          className="checkbox__add-custom"
          onClick={() => setEditing(true)}
          type="button"
        >
          + Add custom date
        </button>
      )}
    </div>
  );
};

CheckBoxList.propTypes = {
  fixedDatesSelected: arrayOf(object),
  setFixedDatesSelected: func,
  variableDatesSelected: arrayOf(object),
  setVariablesDatesSelected: func,
  customDatesSelected: arrayOf(object).isRequired,
  setCustomDatesSelected: func.isRequired,
};

export default CheckBoxList;
