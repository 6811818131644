import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes, { string } from 'prop-types';
import './index.scss';

const Badge = ({ image, title, description, handleSelectMission }) => (
  <div
    className="badge"
    onClick={handleSelectMission}
    data-tip=""
    data-for="Mission"
  >
    <div className="badge__image">
      <img src={image} alt="" />
    </div>
    <div className="badge__title">
      <span>{title}</span>
    </div>
    <ReactTooltip id="Mission" place="top" effect="solid">
      {description}
    </ReactTooltip>
  </div>
);

Badge.propTypes = {
  image: string.isRequired,
  title: string.isRequired,
  description: string,
  handleSelectMission: PropTypes.func,
};

export default Badge;
