/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';
import Select from 'react-select';
import cn from 'classnames';
import { string, bool, func, array, object } from 'prop-types';
import './index.scss';

const Input = ({
  text,
  className,
  placeholder,
  type,
  label,
  select = false,
  options,
  defaultValue,
  isSearchable,
  onChange,
  disabled,
  errorMessage,
  error,
  successMessage,
  ...props
}) => (
  <div className="input-container">
    {label && <span className="input-container__label">{label}</span>}
    {!select && (
      <input
        type={type}
        placeholder={placeholder}
        className={cn('input', className, { disabled })}
        value={text}
        onChange={onChange}
        {...props}
      />
    )}
    {error && errorMessage && (
      <span className="input-container__error">{errorMessage}</span>
    )}
    {select && (
      <Select
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        options={options}
        classNamePrefix="input-container__dropdown"
        onChange={onChange}
      />
    )}
    {successMessage && (
      <span className="input-container__success">{successMessage}</span>
    )}
  </div>
);
Input.propTypes = {
  placeholder: string,
  errorMessage: string,
  error: bool,
  successMessage: string,
  className: string,
  onChange: func.isRequired,
  options: array,
  isSearchable: bool,
  defaultValue: object,
  text: string,
  type: string,
  label: string,
  disabled: bool,
  select: bool,
};

export default Input;
