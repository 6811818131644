/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProduct } from '../../context/ProductContext';
import { validateUrl } from '../../helpers/validators';
import logo from '../../assets/images/givrLogoPrimary.svg';
import './index.scss';

const RedirectToStore = () => {
  const params = useParams();
  const { getProductBySlug, loading } = useProduct();
  const [url, setUrl] = useState(null);
  const [error, setError] = useState('');
  const [timer, setTime] = useState(5);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTime(timer - 1);
      }, 1000);
    } else if (!error && !loading) {
      window.location.href = url;
    }
  }, [timer]);

  useEffect(() => {
    if (params && params.slug) {
      getProductBySlug(params.slug).then((response) => {
        if (
          response &&
          response.product &&
          response.product.product_url &&
          validateUrl(response.product.product_url)
        ) {
          setUrl(response.product.product_url);
        } else {
          setError('An error occurred, try again later.');
        }
      });
    } else {
      setError('An error occurred, try again later.');
    }
  }, [params]);

  return (
    <div className="redirect">
      <div className="redirect__card">
        <div className="redirect__card__container">
          <img src={logo} alt="Logo" />
          <span className="redirect__card__container__title">
            You will be redirected
          </span>
          <p>Givr will redirect you to an external site to get this gift.</p>
          {!error && !loading && <a href={url}>Continue</a>}
          {error && (
            <span className="redirect__card__container__error">{error}</span>
          )}
        </div>
        <div className="redirect__footer">
          <span className="redirect__footer__timer">{timer}</span>
        </div>
      </div>
    </div>
  );
};

export default RedirectToStore;
