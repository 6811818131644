import React, { useState, useEffect } from 'react';
import { map, slice } from 'lodash';
import PropTypes from 'prop-types';
import { navigateTo } from '../../helpers/historyHelper';
import ProductCard from '../ProductCard';
import './index.scss';

const RecommendedProductList = ({
  listTitle,
  products,
  productsNumber = 4,
}) => {
  const [isMobile, setDesktop] = useState(window.innerWidth <= 768);
  const updateMedia = () => {
    setDesktop(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className="recommended-list">
      <div className="recommended-list__text">
        <span className="recommended-list__text__title">{listTitle}</span>
        <a
          onClick={() => navigateTo('/gifts')}
          className="recommended-list__text__link"
        >
          See More
        </a>
      </div>
      <div className="recommended-list__list">
        {map(slice(products, 0, productsNumber), (item) => (
          <ProductCard
            key={item?.id}
            title={item?.title}
            image={item?.img_url?.[0]}
            merchant={item?.merchant}
            price={item?.price}
            className={
              isMobile
                ? 'product-card product-card--medium'
                : 'product-card product-card--large'
            }
            onClick={() => navigateTo(`/gifts/${item?.slug}`)}
          />
        ))}
      </div>
    </div>
  );
};

RecommendedProductList.propTypes = {
  listTitle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsNumber: PropTypes.number,
};

export default RecommendedProductList;
