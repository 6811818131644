/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/Button';
import givrLogoPrimary from '../../assets/images/givrLogoPrimary.svg';
import InputWithFilter from '../InputWithFilter';
import SignUpModal from '../SignUpModal';
import SignInModal from '../SignInModal';
import ReferralModal from '../ReferralModal';
import ResetPasswordModal from '../ResetPasswordModal';
import { navigateTo } from '../../helpers/historyHelper';
import { useUser } from '../../context/UserContext';
import './index.scss';
import NotifiactionsSection from '../NotificationsSection';

const ExpandedHeader = ({ inputSearchRef, search, setSearch }) => {
  const {
    loggedIn,
    user,
    openSignUpModal,
    openSignInModal,
    openResetPasswordModal,
    setOpenSignUpModal,
    showReferralModal,
    setShowReferralModal,
    setOpenSignInModal,
    setOpenResetPasswordModal,
    upcomingEvents,
  } = useUser();

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleGoToLogin = () => {
    setOpenSignUpModal(false);
    setOpenSignInModal(true);
  };

  const handleGoToSignUp = () => {
    setOpenSignInModal(false);
    setOpenSignUpModal(true);
  };

  const handleGoResetPassword = () => {
    setOpenSignInModal(false);
    setOpenResetPasswordModal(true);
  };

  const handleGoLoginFromReset = () => {
    setOpenResetPasswordModal(false);
    setOpenSignInModal(true);
  };

  return (
    <div className="expanded-header">
      {openSignUpModal && (
        <SignUpModal
          handleGoToLogin={handleGoToLogin}
          close={() => setOpenSignUpModal(false)}
        />
      )}
      {openSignInModal && (
        <SignInModal
          handleGoToSignUp={handleGoToSignUp}
          handleForgotPassword={handleGoResetPassword}
          close={() => setOpenSignInModal(false)}
        />
      )}
      {openResetPasswordModal && (
        <ResetPasswordModal
          handleGoBack={handleGoLoginFromReset}
          close={() => setOpenResetPasswordModal(false)}
        />
      )}
      {showReferralModal && (
        <ReferralModal close={() => setShowReferralModal(false)} />
      )}
      <div className="expanded-header__input-container">
        <img
          src={givrLogoPrimary}
          alt=""
          className="expanded-header__input-container__logo"
          onClick={() => navigateTo('/homeDev')}
        />
        <InputWithFilter
          inputRef={inputSearchRef}
          onChange={handleChange}
          placeholder="Find your unique gift"
          value={search}
        />
      </div>
      <div className="expanded-header__button-container">
        {loggedIn ? (
          <NotifiactionsSection
            hasNotifications={false}
            name={user?.firstName}
            notifications={upcomingEvents}
            showReferralModal={() => setShowReferralModal(true)}
          />
        ) : (
          <>
            <button
              type="button"
              className="expanded-header__button-container__button"
              onClick={() => setOpenSignInModal(true)}
            >
              Sign In
            </button>
            <Button
              onClick={() => setOpenSignUpModal(true)}
              className="btn--phantom btn--small"
            >
              Sign up
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

ExpandedHeader.propTypes = {
  inputSearchRef: PropTypes.object,
  search: PropTypes.string,
  setSearch: PropTypes.func,
};

export default ExpandedHeader;
