import React from 'react';
import PropTypes, { string } from 'prop-types';
import './index.scss';

const CategoryCard = ({
  handleSelectCategory,
  title,
  image,
  color = 'white',
}) => {
  const styles = {
    backgroundColor: color,
  };
  return (
    <div style={styles} className="category" onClick={handleSelectCategory}>
      <div className="category__image">
        <img alt="Category" src={image} />
      </div>
      <div className="category__info">
        <div className="category__info__title">
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

CategoryCard.propTypes = {
  handleSelectCategory: PropTypes.func,
  title: string.isRequired,
  image: string.isRequired,
  color: string.isRequired,
};

export default CategoryCard;
