/* eslint-disable no-unused-vars */
import React from 'react';
import { func, string, object } from 'prop-types';
import searchIconPrimary from '../../assets/icons/searchIconPrimary.svg';
import './index.scss';

const InputWithFilter = ({ inputRef, onChange, placeholder, value }) => (
  <div className="input-with-filter">
    <input
      ref={inputRef}
      className="input-with-filter__input"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
    <img src={searchIconPrimary} alt="" className="input-with-filter__icon" />
  </div>
);

InputWithFilter.propTypes = {
  inputRef: object,
  onChange: func.isRequired,
  placeholder: string,
  value: string,
};

export default InputWithFilter;
