/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import ReactModal from 'react-modal';
import { func, string, bool, arrayOf, object } from 'prop-types';
import cross from '../../../assets/icons/cross.svg';
import giftImage from '../../../assets/images/addContact/giftImage.svg';
import { pronountToSegment } from '../../../helpers/parsers';
import { navigateTo } from '../../../helpers/historyHelper';
import Button from '../../shared/Button';
import styles, { mobileStyles } from './modalStyles';
import './index.scss';

const StepTwo = ({
  isMobile,
  loading,
  next,
  close,
  name,
  pronoun,
  segments,
}) => (
  <ReactModal
    ariaHideApp={false}
    style={isMobile ? mobileStyles : styles}
    isOpen={true}
  >
    <div className="step-two">
      <img src={cross} alt="" onClick={close} />
      <img className="step-two__gift" src={giftImage} alt="" />
      <span className="step-two__title">{`${name}'s been added to your contacts!`}</span>
      <span className="step-two__subtitle">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
        phasellus quisque risus ut posuere vel eros erat.
      </span>
      <Button
        className="step-two__explorer-btn btn--primary btn--x-large"
        onClick={() => {
          close();
          navigateTo('/gifts', {
            segmentSelected: pronountToSegment(segments, pronoun),
          });
        }}
      >{`EXPLORE GIFTS FOR ${name}`}</Button>
      <button
        className="step-two__add-contact"
        onClick={next}
        disabled={loading}
        type="button"
      >
        Add Another Contact
      </button>
    </div>
  </ReactModal>
);

StepTwo.propTypes = {
  isMobile: bool.isRequired,
  loading: bool.isRequired,
  next: func.isRequired,
  close: func.isRequired,
  name: string.isRequired,
  pronoun: string.isRequired,
  segments: arrayOf(object),
};

export default StepTwo;
