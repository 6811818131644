const styles = {
  content: {
    position: 'absolute',
    width: '38.946vw',
    background: '#fff',
    outline: 'none',
    padding: '1.489vw 2.269vw 3.344vw 3.953vw',
    left: '50%',
    marginLeft: '-19.473vw',
    overflow: 'auto',
    height: 'fit-content',
    maxHeight: '99vh',
    top: '0.5vh',
  },
  overlay: {
    backgroundColor: 'rgba(131, 148, 153, 0.5)',
    zIndex: 1000,
  },
};

export const mobileStyles = {
  content: {
    position: 'absolute',
    height: '96vh',
    width: '93%',
    background: '#fff',
    outline: 'none',
    padding: '2.489vw 3.953vw 3.66vw 3.953vw',
    overflow: 'auto',
    maxHeight: '99vh',
    top: 0,
    left: 0,
    margin: '3.83vw',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(131, 148, 153, 0.5)',
    zIndex: 1000,
  },
};

export default styles;
