import React from 'react';
import { map, find } from 'lodash';
import PropTypes from 'prop-types';
import { useProduct } from '../../context/ProductContext';
import { useUser } from '../../context/UserContext';
import { navigateTo } from '../../helpers/historyHelper';
import AddContactModal from '../AddContactModal';
import cake from '../../assets/icons/cake.svg';
import blackStar from '../../assets/icons/blackStar.svg';
import './index.scss';

const AllContacts = ({ contacts }) => {
  const { segments } = useProduct();
  const { showAddContactModal, setShowAddContactModal } = useUser();

  const handleSuggestion = (contact) => {
    switch (contact.pronoun) {
      case 'She/Her':
        return find(segments, (item) => item.name === 'For Her');
      case 'He/Him':
        return find(segments, (item) => item.name === 'For Him');
      default:
        return find(segments, (item) => item.name === 'All');
    }
  };
  return (
    <div className="all-contacts">
      {showAddContactModal && (
        <AddContactModal close={() => setShowAddContactModal(false)} />
      )}
      <img className="all-contacts__star" src={blackStar} alt="" />
      <button
        onClick={() => setShowAddContactModal(true)}
        className="all-contacts__add-contact"
        type="button"
      >
        ADD NEW CONTACT
      </button>
      <div className="all-contacts__list">
        {map(contacts, (contact, index) => (
          <div className="all-contacts__list__item" key={index}>
            <span className="all-contacts__list__item__name">
              {contact?.name}
            </span>
            <span className="all-contacts__list__item__birthday">
              <img src={cake} alt="" />
              {contact?.birthdate}
            </span>
            <span className="all-contacts__list__item__relation">
              {contact?.relationship_type}
            </span>
            <button
              className="all-contacts__list__item__explore"
              type="button"
              onClick={() =>
                navigateTo('/gifts', {
                  segmentSelected: handleSuggestion(contact),
                })
              }
            >
              Explore Gifts
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

AllContacts.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
};

export default AllContacts;
