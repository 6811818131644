import React from 'react';
import { string } from 'prop-types';
import './index.scss';

const SmallBadge = ({ image, name }) => (
  <div className="small-badge">
    <div className="small-badge__image">
      <img src={image} alt="" />
    </div>
    <span className="small-badge__name">{name}</span>
  </div>
);

SmallBadge.propTypes = {
  image: string.isRequired,
  name: string,
};

export default SmallBadge;
