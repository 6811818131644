/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { size, isEmpty } from 'lodash';
import ReactModal from 'react-modal';
import { navigateTo } from '../../helpers/historyHelper';
import TopBar from '../../components/TopBar';
import Tabs from '../../components/Tabs';
import RecommendedProductList from '../../components/RecommendedProductList';
import EventReminder from '../../components/EventReminder';
import AddContactSection from '../../components/AddContactSection';
import AboutGivr from '../../components/AboutGivr';
import ShopByMission from '../../components/ShopByMission';
import PopularCategories from '../../components/PopularCategories';
import ImportantPeopleSection from '../../components/ImportantPeople';
import Footer from '../../components/Footer';
import AddContactModal from '../../components/AddContactModal';
import { useProduct } from '../../context/ProductContext';
import { useUser } from '../../context/UserContext';
import './index.scss';

const Home = () => {
  const { getProducts, categories, popularCategories } = useProduct();
  const {
    loggedIn,
    upcomingEvents,
    showAddContactModal,
    setShowAddContactModal,
    setOpenSignInModal,
    setShowReferralModal,
  } = useUser();

  const [products, setProducts] = useState([]);
  const location = useLocation();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  if (localStorage.getItem('popupShown') === null)
    localStorage.setItem('popupShown', true);

  const showReminderModal = () => localStorage.getItem('popupShown') === 'true';

  const handleCloseModal = () => {
    localStorage.setItem('popupShown', false);
    forceUpdate();
  };

  const handleAddContact = () => {
    if (loggedIn) {
      setShowAddContactModal(true);
      handleCloseModal();
    } else {
      setOpenSignInModal(true);
    }
  };

  const fetchProducts = () => {
    getProducts('featured=true').then((response) => {
      setProducts(response);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (location.search) {
      const url = new URLSearchParams(location.search);
      const open = url.get('open');
      if (open && open.match('referral')) setShowReferralModal(true);
    }
  }, []);

  return (
    <div className="app-container">
      {showAddContactModal && (
        <AddContactModal close={() => setShowAddContactModal(false)} />
      )}
      {showReminderModal() && (
        <ReactModal
          ariaHideApp={false}
          className="modal"
          overlayClassName="overlay"
          isOpen
        >
          <ImportantPeopleSection
            handleAddContact={handleAddContact}
            handleClose={handleCloseModal}
          />
        </ReactModal>
      )}
      <TopBar />
      <Tabs
        list={categories}
        handleSelectCategory={(category) =>
          navigateTo('/gifts', { categorySelected: category })
        }
      />
      {size(upcomingEvents) > 0 && (
        <EventReminder upcomingEvents={upcomingEvents} />
      )}
      {!isEmpty(products?.recommended_products) && (
        <RecommendedProductList
          listTitle="Recommended"
          products={products?.recommended_products}
          productsNumber={8}
        />
      )}
      {!isEmpty(products?.popular_products) && (
        <RecommendedProductList
          listTitle="Popular Today"
          products={products?.popular_products}
          productsNumber={4}
        />
      )}

      <AddContactSection handleAddContact={handleAddContact} />
      <PopularCategories categories={popularCategories} />
      <ShopByMission />
      <AboutGivr />
      <Footer />
    </div>
  );
};

export default Home;
