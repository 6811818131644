import React from 'react';
import { map } from 'lodash';
import Badge from '../Badge';
import { useProduct } from '../../context/ProductContext';
import { navigateTo } from '../../helpers/historyHelper';
import './index.scss';

const ShopByMission = () => {
  const { missions } = useProduct();
  return (
    <div className="shop-by-mission">
      <div className="shop-by-mission__title">
        <span>Shop by Mission</span>
      </div>
      <div className="shop-by-mission__list">
        {map(missions, (item, index) => (
          <Badge
            key={`mission-${index}`}
            title={item?.name}
            image={item?.picture}
            description={item.tooltip}
            handleSelectMission={() => {
              navigateTo('/gifts', { missions: item });
            }}
          />
        ))}
      </div>
    </div>
  );
};
export default ShopByMission;
