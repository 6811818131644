import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { CSSTransition } from 'react-transition-group';
import TabButton from '../TabButton';
import './index.scss';

const MobileTabs = ({
  showMainCategories,
  resetFilters,
  list,
  categorySelected,
  setCategorySelected,
  setShowMainCategories,
}) => (
  <CSSTransition
    timeout={300}
    in={showMainCategories}
    mountOnEnter
    unmountOnExit
    classNames="mobile-tabs__items"
  >
    <div className="mobile-tabs">
      {map(list, (date, index) => (
        <TabButton
          key={`mobile-categories-${index}`}
          onClick={() => {
            resetFilters();
            setCategorySelected(date);
            setShowMainCategories(false);
          }}
          isSelected={categorySelected?.id === date?.id}
          text={date?.name}
        />
      ))}
    </div>
  </CSSTransition>
);
MobileTabs.propTypes = {
  showMainCategories: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object),
  resetFilters: PropTypes.func,
  categorySelected: PropTypes.object,
  setCategorySelected: PropTypes.func,
  setShowMainCategories: PropTypes.func,
};

export default MobileTabs;
