import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { navigateTo } from '../../../helpers/historyHelper';
import { pronountToSegment } from '../../../helpers/parsers';
import heart from '../../../assets/icons/heart.svg';

import './index.scss';

const Anniversary = ({ events, segments }) =>
  map(events, (item, index) => (
    <div key={`anniversary-${index}`} className="anniversary">
      <div className="anniversary__event">
        <img src={heart} alt="" />
        <span className="anniversary__event__type">Anniversary with&nbsp;</span>
        <span className="anniversary__event__user">{item.contact_name}</span>
      </div>
      <button
        className="anniversary__button"
        type="button"
        onClick={() =>
          navigateTo('/gifts', {
            segmentSelected: pronountToSegment(segments, item.contact_pronoun),
          })
        }
      >
        Explore Gifts
      </button>
    </div>
  ));

Anniversary.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
};
export default Anniversary;
