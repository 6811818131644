/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import instagram from '../../assets/icons/instagram.svg';
import twitter from '../../assets/icons/twitter.svg';
import pinterest from '../../assets/icons/pinterest.svg';
import givrLogoWhite from '../../assets/images/givrLogoWhite.svg';
import './index.scss';

const Footer = () => (
  <div className="footer">
    <div className="footer__first-column">
      <img src={givrLogoWhite} alt="" />
    </div>
    <div className="footer__second-column">
      <div className="footer__second-column__information">
        <span className="footer__second-column__information__title">
          CONTACT
        </span>
        <span className="footer__second-column__information__text">
          +1 (555) 555-5555
        </span>
        <span className="footer__second-column__information__text">
          hey@shopgivr.com
        </span>
        <span className="footer__second-column__information__text">
          123 First Street, San Francisco CA 1234
        </span>
      </div>
      <div className="footer__second-column__links">
        <span className="footer__second-column__links__title">COMPANY</span>
        <a className="footer__second-column__links__text" href="/about">
          About
        </a>
        <a className="footer__second-column__links__text" href="/about">
          Merchants
        </a>
        <a className="footer__second-column__links__text" href="/terms">
          Terms of use
        </a>
        <a className="footer__second-column__links__text" href="/privacy">
          Privacy Policy
        </a>
      </div>
    </div>
    <div className="footer__third-column">
      <div className="footer__third-column__icons">
        <a
          href="https://www.instagram.com/shop.givr/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={instagram}
            alt=""
            className="footer__third-column__icons__icon"
          />
        </a>
        <a
          href="https://www.instagram.com/shop.givr/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={twitter}
            alt=""
            className="footer__third-column__icons__icon"
          />
        </a>
        <a
          href="https://www.instagram.com/shop.givr/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={pinterest}
            alt=""
            className="footer__third-column__icons__icon"
          />
        </a>
      </div>
      <span className="footer__third-column__copyright">{`©${new Date().getFullYear()} GIVR`}</span>
    </div>
  </div>
);

export default Footer;
