import React from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/Button';
import baloonSection from '../../assets/images/addContact/baloonSection.svg';
import baloonSectionMobile from '../../assets/images/addContact/baloonSectionMobile.svg';
import './index.scss';

const AddContactSection = ({ handleAddContact }) => (
  <div className="add-contact-section">
    <div className="add-contact-section__left">
      <span className="add-contact-section__left__text">
        Get customized recommendations based on your contacts and important
        dates
      </span>
      <Button
        onClick={handleAddContact}
        className="btn--medium-large btn--primary"
      >
        ADD CONTACTS
      </Button>
    </div>
    <div className="add-contact-section__right">
      <img
        alt=""
        className="add-contact-section__right__baloon"
        src={baloonSection}
      />
      <img
        alt=""
        className="add-contact-section__right__baloon-mobile"
        src={baloonSectionMobile}
      />
    </div>
  </div>
);

AddContactSection.propTypes = {
  handleAddContact: PropTypes.func,
};

export default AddContactSection;
