import React, { useState, useEffect } from 'react';
import { map, concat, filter } from 'lodash';
import { func } from 'prop-types';
import snakecaseKeys from 'snakecase-keys';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import {
  validateMMDD,
  validateMMDDYY,
  validateYear,
} from '../../helpers/validators';
import { useUser } from '../../context/UserContext';
import { useProduct } from '../../context/ProductContext';

const AddContactModal = ({ close }) => {
  const [isMobile, setDesktop] = useState(window.innerWidth <= 768);
  const { segments } = useProduct();
  const { addContact, loading, defaultEvents } = useUser();
  const [modalStep, setModalStep] = useState(1);

  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [year, setYear] = useState('');
  const [relationshipType, setRelationshipType] = useState('');
  const [pronoun, setPronoun] = useState('She/Her');

  const [fixedDatesSelected, setFixedDatesSelected] = useState([]);
  const [variableDatesSelected, setVariablesDatesSelected] = useState([]);
  const [customDatesSelected, setCustomDatesSelected] = useState([]);

  const [birthDateError, setBirthdayError] = useState('');
  const [birthYearError, setBirthYearError] = useState('');

  useEffect(() => {
    if (defaultEvents && defaultEvents.fixed && defaultEvents.variable) {
      const fixedEvents = map(defaultEvents.fixed, (item) => ({
        ...item,
        selected: false,
      }));
      setFixedDatesSelected(fixedEvents);
      const variablesEvents = map(
        filter(defaultEvents?.variable, (item) => item !== 'Birthday'),
        (item, index) => ({
          id: index,
          name: item,
          date: null,
          error: '',
          selected: false,
        })
      );
      setVariablesDatesSelected(variablesEvents);
    }
  }, [defaultEvents]);

  const handleSubmit = () => {
    setBirthdayError('');
    setBirthYearError('');
    const variableEvent = map(variableDatesSelected, (item) => ({
      ...item,
      error: '',
    }));
    setVariablesDatesSelected(variableEvent);
    let errorOccurred = false;

    if (!validateMMDD(birthday)) {
      errorOccurred = true;
      setBirthdayError('Invalid format');
    }

    if (!validateYear(year)) {
      errorOccurred = true;
      setBirthYearError('Invalid year');
    }

    const variableEvensErrorCheck = map(variableDatesSelected, (item) => {
      const newItem = item;
      if (!item.selected) return item;
      if (!item.date) {
        newItem.error = 'Set date';
        errorOccurred = true;
      } else if (!validateMMDDYY(item.date)) {
        newItem.error = 'Invalid format';
        errorOccurred = true;
      }
      return newItem;
    });

    setVariablesDatesSelected(variableEvensErrorCheck);

    if (!errorOccurred) {
      const yearStr = year ? `/${year}` : '';
      const dataFormated = {
        name,
        pronoun,
        birthday: `${birthday}${yearStr}`,
        relationshipType,
        customDatesSelected: concat(
          filter(variableDatesSelected, (item) => item.selected),
          filter(customDatesSelected, (item) => item.selected)
        ),
        datesSelected: fixedDatesSelected,
      };

      addContact({ contact: snakecaseKeys(dataFormated) })
        .then(() => {
          setModalStep(2);
        })
        .catch(() => {});
    }
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <>
      {modalStep === 1 && (
        <StepOne
          isMobile={isMobile}
          loading={loading}
          name={name}
          setName={setName}
          birthday={birthday}
          setBirthday={setBirthday}
          year={year}
          setYear={setYear}
          relationshipType={relationshipType}
          setRelationshipType={setRelationshipType}
          pronoun={pronoun}
          setPronoun={setPronoun}
          fixedDatesSelected={fixedDatesSelected}
          setFixedDatesSelected={setFixedDatesSelected}
          variableDatesSelected={variableDatesSelected}
          setVariablesDatesSelected={setVariablesDatesSelected}
          customDatesSelected={customDatesSelected}
          setCustomDatesSelected={setCustomDatesSelected}
          birthDateError={birthDateError}
          birthYearError={birthYearError}
          close={close}
          handleSubmit={handleSubmit}
        />
      )}
      {modalStep === 2 && (
        <StepTwo
          isMobile={isMobile}
          loading={loading}
          close={close}
          next={() => setModalStep(1)}
          name={name}
          pronoun={pronoun}
          segments={segments}
        />
      )}
    </>
  );
};

AddContactModal.propTypes = {
  close: func.isRequired,
};

export default AddContactModal;
