/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/Button';
import givrLogoWhite from '../../assets/images/givrLogoWhite.svg';
import givrLogo from '../../assets/images/givrLogoPrimary.svg';
import HeaderInput from './HeaderInput';
import SignUpModal from '../SignUpModal';
import SignInModal from '../SignInModal';
import ResetPasswordModal from '../ResetPasswordModal';
import NotifiactionsSection from '../NotificationsSection';
import { useUser } from '../../context/UserContext';
import { navigateTo } from '../../helpers/historyHelper';
import './index.scss';
import ReferralModal from '../ReferralModal';

const Header = ({ isDark }) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search?.length > 2) {
      navigateTo('/gifts', { search });
    }
  }, [search]);

  const {
    loggedIn,
    user,
    openSignUpModal,
    openSignInModal,
    openResetPasswordModal,
    showReferralModal,
    setOpenSignUpModal,
    upcomingEvents,
    setOpenSignInModal,
    setOpenResetPasswordModal,
    setShowReferralModal,
  } = useUser();

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleGoToLogin = () => {
    setOpenSignUpModal(false);
    setOpenSignInModal(true);
  };

  const handleGoToSignUp = () => {
    setOpenSignInModal(false);
    setOpenSignUpModal(true);
  };

  const handleGoResetPassword = () => {
    setOpenSignInModal(false);
    setOpenResetPasswordModal(true);
  };

  const handleGoLoginFromReset = () => {
    setOpenResetPasswordModal(false);
    setOpenSignInModal(true);
  };
  return (
    <div className={`header ${isDark ? 'header--dark' : ''}`}>
      {openSignUpModal && (
        <SignUpModal
          handleGoToLogin={handleGoToLogin}
          close={() => setOpenSignUpModal(false)}
        />
      )}
      {openSignInModal && (
        <SignInModal
          handleGoToSignUp={handleGoToSignUp}
          handleForgotPassword={handleGoResetPassword}
          close={() => setOpenSignInModal(false)}
        />
      )}
      {openResetPasswordModal && (
        <ResetPasswordModal
          handleGoBack={handleGoLoginFromReset}
          close={() => setOpenResetPasswordModal(false)}
        />
      )}
      {showReferralModal && (
        <ReferralModal close={() => setShowReferralModal(false)} />
      )}
      <div className="header__input-container">
        <img
          src={isDark ? givrLogoWhite : givrLogo}
          alt=""
          className="header__input-container__logo"
          onClick={() => navigateTo('/homeDev')}
        />
        <HeaderInput
          isDark={isDark}
          value={search}
          onChange={handleChange}
          placeholder="Find your unique gift"
        />
      </div>
      <div className="header__button-container">
        {loggedIn ? (
          <NotifiactionsSection
            isDark={isDark}
            hasNotifications={false}
            name={user?.firstName}
            notifications={upcomingEvents}
            showReferralModal={() => setShowReferralModal(true)}
          />
        ) : (
          <>
            <button
              onClick={() => setOpenSignInModal(true)}
              type="button"
              className="header__button-container__button"
            >
              Sign In
            </button>
            <Button
              onClick={() => setOpenSignUpModal(true)}
              className="btn--primary-dark btn--small"
            >
              Sign up
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  isDark: PropTypes.bool,
};
export default Header;
