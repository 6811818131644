/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ProfileTabs from '../../components/ProfileTabs';
import UpComingDates from '../../components/UpComingDates';
import AllContacts from '../../components/AllContacts';
import { useUser } from '../../context/UserContext';
import './index.scss';
import { navigateTo } from '../../helpers/historyHelper';

const Profile = () => {
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const { user, upcomingEvents, allContacts, logout, fetchEvents } = useUser();

  useEffect(() => {
    if (!isOpenContactModal) {
      fetchEvents();
    }
  }, [isOpenContactModal]);

  const handleLogout = () => {
    logout();
    navigateTo('/');
  };

  return (
    <div className="profile">
      <div>
        <Header theme="light" />
        <div className="profile__banner">
          <div className="profile__banner__buttons">
            <span className="profile__banner__buttons__title">My Account</span>
            <div>
              <button
                className="profile__banner__buttons__button profile__banner__buttons__button--edit"
                type="button"
              >
                Edit Account
              </button>
              <button
                className="profile__banner__buttons__button profile__banner__buttons__button--logout"
                type="button"
                onClick={handleLogout}
              >
                <span>Log out</span>
              </button>
            </div>
          </div>
          <span className="profile__banner__name">
            {`${user.firstName} ${user.lastName}`}
          </span>
          <span className="profile__banner__email">{user.email}</span>
        </div>
        <ProfileTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === 0 && <UpComingDates events={upcomingEvents} />}
        {selectedTab === 1 && (
          <AllContacts
            isOpenContactModal={isOpenContactModal}
            setIsOpenContactModal={setIsOpenContactModal}
            contacts={allContacts}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
