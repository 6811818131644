import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import './index.scss';

const PriceFilter = ({ list, priceSelected, setPriceSelected }) => (
  <div className="price-filter">
    <span className="price-filter__title">Price</span>
    {map(list, (data, index) => (
      <button
        key={`price-${index}`}
        className={`price-filter__filter ${
          (priceSelected?.id === data?.id &&
            'price-filter__filter--selected') ||
          ''
        }`}
        type="button"
        onClick={() => setPriceSelected(data)}
      >
        {data.name}
      </button>
    ))}
  </div>
);

PriceFilter.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  priceSelected: PropTypes.object,
  setPriceSelected: PropTypes.func,
};

export default PriceFilter;
