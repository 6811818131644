import React from 'react';
import './index.scss';
import { string } from 'prop-types';
import { navigateTo } from '../../helpers/historyHelper';
import greenBannerPlants from '../../assets/images/banner/greenBannerPlants.svg';
import creamStar from '../../assets/icons/creamStar.svg';

const Banner = ({ image }) => (
  <div className="banner">
    <div className="banner__title">
      <span className="banner__title__text">
        Get personalized gift recommendations, right on time!
      </span>
      <button
        onClick={() => navigateTo('/gifts')}
        type="button"
        className="banner__title__button"
      >
        GET STARTED
      </button>
    </div>
    <div className="banner__images">
      <img src={image} alt="" className="banner__images__image" />
      <img src={greenBannerPlants} alt="" className="banner__images__plants" />
      <img src={creamStar} alt="" className="banner__images__star" />
    </div>
  </div>
);

Banner.propTypes = {
  image: string,
};
export default Banner;
