/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { func, string, bool, object, arrayOf } from 'prop-types';
import ListSelector from '../../ListSelector';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import cross from '../../../assets/icons/cross.svg';
import CheckBoxList from '../../CheckBoxList';
import styles, { mobileStyles } from './modalStyles';
import { relationship } from '../../../constants/contact';
import './index.scss';

const StepOne = ({
  isMobile,
  loading,
  name,
  setName,
  birthday,
  setBirthday,
  year,
  setYear,
  relationshipType,
  setRelationshipType,
  pronoun,
  setPronoun,
  fixedDatesSelected,
  setFixedDatesSelected,
  variableDatesSelected,
  setVariablesDatesSelected,
  customDatesSelected,
  setCustomDatesSelected,
  birthDateError,
  birthYearError,
  close,
  handleSubmit,
}) => {
  const [firstStep, setFirstStep] = useState(true);

  const validateKey = (e) => {
    let keynum;
    if (window.event) {
      // IE
      keynum = e.keyCode;
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which;
    }
    if (birthday.length === 2 && !birthday.match('/') && !(keynum === 8)) {
      setBirthday(`${birthday}/`);
    }
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setBirthday(val);
  };

  return (
    <ReactModal
      ariaHideApp={false}
      style={isMobile ? mobileStyles : styles}
      isOpen={true}
    >
      <div className="step-one">
        <img src={cross} alt="" onClick={close} />
        <span className="step-one__title">Add a Contact</span>
        <span className="step-one__subtitle">
          Get customized recommendations and reminders based on your contacts
          and important dates
        </span>
        {firstStep && (
          <>
            <Input
              className="input--large"
              label="Contact Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <div className="step-one__inputs">
              <Input
                className="step-one__inputs__input-container input--small"
                label="Pronoun"
                select
                defaultValue={{ value: 'She/Her', label: 'She/Her' }}
                options={[
                  { value: 'She/Her', label: 'She/Her' },
                  { value: 'He/Him', label: 'He/Him' },
                  { value: 'They/Them', label: 'They/Them' },
                ]}
                value={pronoun}
                isSearchable={false}
                onChange={(event) => setPronoun(event.value)}
              />
              <div className="step-one__inputs__dates">
                <Input
                  className="step-one__inputs__input-container input--small"
                  placeholder="MM/DD"
                  error={birthDateError !== ''}
                  errorMessage={birthDateError}
                  label="Birthdate"
                  value={birthday}
                  onChange={handleChange}
                  onKeyDown={validateKey}
                  maxLength={5}
                />
                <Input
                  className="step-one__inputs__input-container input--small"
                  placeholder="YYYY"
                  error={birthYearError !== ''}
                  errorMessage={birthYearError}
                  label="Birth Year (optional)"
                  maxLength={4}
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                />
              </div>
            </div>
            <Input
              className="input--large"
              label="Type of Relationship"
              onClick={() => setFirstStep(false)}
              value={relationshipType}
              onChange={() => {}}
            />
          </>
        )}
        {!firstStep && (
          <ListSelector
            options={relationship}
            handleClick={(value) => {
              setRelationshipType(value?.name);
              setFirstStep(true);
            }}
          />
        )}
        {firstStep && relationshipType && (
          <CheckBoxList
            fixedDatesSelected={fixedDatesSelected}
            setFixedDatesSelected={setFixedDatesSelected}
            variableDatesSelected={variableDatesSelected}
            setVariablesDatesSelected={setVariablesDatesSelected}
            customDatesSelected={customDatesSelected}
            setCustomDatesSelected={setCustomDatesSelected}
          />
        )}
        <Button
          disabled={!(relationshipType && name && birthday) || loading}
          className="btn--primary btn--x-large"
          onClick={handleSubmit}
        >
          ADD A CONTACT
        </Button>
      </div>
    </ReactModal>
  );
};

StepOne.propTypes = {
  isMobile: bool.isRequired,
  handleSubmit: func.isRequired,
  close: func.isRequired,
  birthDateError: string.isRequired,
  birthYearError: string.isRequired,
  loading: bool.isRequired,
  name: string,
  setName: func,
  birthday: string,
  setBirthday: func,
  year: string,
  setYear: func,
  relationshipType: string,
  setRelationshipType: func,
  pronoun: string,
  setPronoun: func,
  fixedDatesSelected: arrayOf(object),
  setFixedDatesSelected: func,
  variableDatesSelected: arrayOf(object),
  setVariablesDatesSelected: func,
  customDatesSelected: arrayOf(object),
  setCustomDatesSelected: func,
};

export default StepOne;
