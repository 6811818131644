/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { map } from 'lodash';
import { array, func } from 'prop-types';
import './index.scss';

const ListSelector = ({ options, handleClick }) => (
  <div className="list-selector">
    {map(options, (option, key) => (
      <div key={key} className="list-selector__value">
        <button type="button" onClick={() => handleClick(option)}>
          {option.name}
        </button>
      </div>
    ))}
  </div>
);

ListSelector.propTypes = {
  options: array.isRequired,
  handleClick: func.isRequired,
};

export default ListSelector;
