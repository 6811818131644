import React from 'react';
import PropTypes from 'prop-types';
import cross from '../../../assets/icons/cross.svg';
import blackStar from '../../../assets/icons/blackStar.svg';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import './index.scss';

const FirstStep = ({
  setEmailAddress,
  close,
  error,
  handleSendInvitation,
  emailError,
  emailAddress,
}) => (
  <div className="first-step">
    <div className="first-step__header">
      <img src={blackStar} alt="" className="first-step__header__blackStar" />
      <span className="first-step__header__title">Refer a friend</span>
    </div>
    <div className="first-step__close" onClick={close}>
      <img src={cross} alt="" />
    </div>
    <Input
      className="input--medium-large"
      label="Email address"
      type="email"
      errorMessage={emailError}
      error={emailError !== ''}
      placeholder="Email address"
      value={emailAddress}
      onChange={(event) => setEmailAddress(event.target.value)}
    />
    <Button
      disabled={!emailAddress}
      className="btn--primary btn--large"
      onClick={handleSendInvitation}
    >
      SEND INVITE
    </Button>
    {error && (
      <div className="first-step__error">
        <span>{error}</span>
      </div>
    )}
  </div>
);

FirstStep.propTypes = {
  setEmailAddress: PropTypes.func,
  close: PropTypes.func,
  error: PropTypes.string,
  handleSendInvitation: PropTypes.func,
  emailError: PropTypes.string,
  emailAddress: PropTypes.string,
};

export default FirstStep;
