/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { map, concat, isEmpty, ceil } from 'lodash';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner';
import { CSSTransition } from 'react-transition-group';
import { useProduct } from '../../context/ProductContext';
import { PRICES } from '../../constants/gifts';
import arrowUp from '../../assets/icons/arrowUp.svg';
import Footer from '../../components/Footer';
import ExpandedHeader from '../../components/ExpandedHeader';
import MerchantFilter from '../../components/GiftFilters/MerchantFilter';
import Tabs from '../../components/Tabs';
import MobileTabs from '../../components/MobileTabs';
import ClientTypeFilter from '../../components/GiftFilters/ClientTypeFilter';
import MissionTypeFilter from '../../components/GiftFilters/MissionTypeFilter';
import PriceFilter from '../../components/GiftFilters/PriceFilter';
import ProductCard from '../../components/ProductCard';
import { navigateTo } from '../../helpers/historyHelper';
import ContactBanner from '../../components/ContactBanner';
import arrowDown from '../../assets/icons/arrowDown.svg';
import { useUser } from '../../context/UserContext';
import useDebounce from '../../hooks/useDebounce';
import './index.scss';

const Gifts = () => {
  const { categories, missions, segments, getProducts, loading } = useProduct();
  const location = useLocation();
  const inputSearchRef = useRef();
  const [showMainCategories, setShowMainCategories] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isMobile, setDesktop] = useState(window.innerWidth <= 768);
  const [isOpenBanner, setIsOpenBanner] = useState(false);
  const [page, setPage] = useState(1);
  const [nextLoading, setLoading] = useState(true);
  const { loggedIn } = useUser();

  const [products, setProducts] = useState([]);

  const [categorySelected, setCategorySelected] = useState({
    id: null,
    name: 'All Gifts',
  });
  const [segmentSelected, setSegmentSelected] = useState(null);

  const [missionSelected, setMissionSelected] = useState({
    id: null,
    name: 'On a Mission!',
  });

  const [priceSelected, setPriceSelected] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const debouncedMerchant = useDebounce(merchant, 500);
  const [search, setSearch] = useState(null);
  const debouncedSearch = useDebounce(search, 500);
  const [showGoUpBtn, setShowGoUpBtn] = useState(false);

  const fetchProducts = (page = 1) => {
    setLoading(true);
    const params = [];
    if (categorySelected && categorySelected.id) {
      params.push(`category_ids=${categorySelected?.id}`);
    }
    if (segmentSelected) {
      params.push(`segment_ids=${segmentSelected.id}`);
    }
    if (missionSelected) {
      params.push(`mission_ids=${missionSelected.id}`);
    }
    if (priceSelected?.moreThan) {
      params.push(`min_price=${priceSelected.moreThan}`);
    }
    if (priceSelected?.lessThan) {
      params.push(`max_price=${priceSelected.lessThan}`);
    }
    if (debouncedMerchant?.length > 2) {
      params.push(`merchant_name=${debouncedMerchant}`);
    }
    if (debouncedSearch?.length > 2) {
      params.push(`search=${debouncedSearch}`);
    }
    params.push(`page=${page}`);
    const query = params.join('&');

    getProducts(query)
      .then((data) => {
        if (!isEmpty(data)) {
          const newProducts = concat(products, data);
          if (page === 1) {
            setProducts(data);
          } else {
            setProducts(newProducts);
          }
          setPage(page);
        }
      })
      .finally(() => setLoading(false));
  };

  const resetFilters = () => {
    if (
      segmentSelected ||
      missionSelected ||
      priceSelected ||
      debouncedMerchant ||
      debouncedSearch
    ) {
      setLoading(true);
      setProducts([]);
      setShowAll(false);
      setSegmentSelected(null);
      setMissionSelected(null);
      setPriceSelected(null);
      setMerchant('');
      setSearch('');
    }
  };

  useEffect(() => {
    if (location && location.data) {
      if (location.data.search) {
        inputSearchRef?.current?.focus();
        setSearch(location.data.search);
      }
      if (location.data.segmentSelected) {
        setSegmentSelected(location.data.segmentSelected);
      }
      if (location.data.categorySelected) {
        setCategorySelected(location.data.categorySelected);
      }
      if (location.data.missionSelected) {
        setMissionSelected(location.data.missionSelected);
      }
    }
  }, [location]);

  useEffect(() => {
    setProducts([]);
    fetchProducts(1);
  }, [
    categorySelected,
    segmentSelected,
    missionSelected,
    priceSelected,
    debouncedSearch,
    debouncedMerchant,
  ]);

  useEffect(() => {
    if (loggedIn) {
      setTimeout(() => {
        setIsOpenBanner(true);
      }, 10000);
    }
  }, [loggedIn]);

  const updateMedia = () => {
    setDesktop(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const handleScroll = () => {
    if (window.pageYOffset > 3000) {
      setShowGoUpBtn(true);
    } else {
      setShowGoUpBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="gift-container">
      <ExpandedHeader
        inputSearchRef={inputSearchRef}
        search={search}
        setSearch={setSearch}
      />
      <Tabs
        list={categories}
        categorySelected={categorySelected}
        handleSelectCategory={(category) => {
          resetFilters();
          setCategorySelected(category);
        }}
      />
      <div className="gift-container__content">
        <div className="gift-container__content__left">
          <div
            className="gift-container__content__left__menu"
            onClick={() =>
              isMobile && setShowMainCategories(!showMainCategories)
            }
          >
            <span className="gift-container__content__left__menu__selected">
              {categorySelected?.name}
            </span>
            <img
              src={arrowDown}
              alt=""
              className="gift-container__content__left__menu__icon"
            />
            <button
              className="gift-container__content__left__menu__clear-btn"
              type="button"
              onClick={() => {
                resetFilters();
              }}
            >
              <p>clear</p>
            </button>
          </div>
          <MobileTabs
            showMainCategories={showMainCategories}
            list={categories}
            categorySelected={categorySelected}
            setCategorySelected={setCategorySelected}
            resetFilters={resetFilters}
            setShowMainCategories={setShowMainCategories}
          />
          <div className="gift-container__filter-buttons">
            <button
              className="gift-container__filter-buttons__button"
              type="button"
              onClick={() => setShowAll(!showAll)}
            >
              <p>{`${showAll ? '-' : '+'}Filters`}</p>
            </button>
            <button
              className="gift-container__filter-buttons__button"
              type="button"
              onClick={() => {
                resetFilters();
              }}
            >
              <p>clear</p>
            </button>
          </div>
          {isMobile ? (
            <CSSTransition
              timeout={300}
              in={showAll}
              classNames="gift-container__content__left__menu__items"
              mountOnEnter
              unmountOnExit
            >
              <div className="gift-container__content__left__menu__items-enter-done">
                <ClientTypeFilter
                  list={segments}
                  isMobile={isMobile}
                  segmentSelected={segmentSelected}
                  setSegmentSelected={setSegmentSelected}
                />
                <MerchantFilter merchant={merchant} setMerchant={setMerchant} />
                <MissionTypeFilter
                  list={missions}
                  missionSelected={missionSelected}
                  setMissionSelected={setMissionSelected}
                />
                <PriceFilter
                  list={PRICES}
                  priceSelected={priceSelected}
                  setPriceSelected={setPriceSelected}
                />
              </div>
            </CSSTransition>
          ) : (
            <div>
              <ClientTypeFilter
                list={segments}
                segmentSelected={segmentSelected}
                setSegmentSelected={setSegmentSelected}
              />
              <MerchantFilter merchant={merchant} setMerchant={setMerchant} />
              <MissionTypeFilter
                list={missions}
                missionSelected={missionSelected}
                setMissionSelected={setMissionSelected}
              />
              <PriceFilter
                list={PRICES}
                priceSelected={priceSelected}
                setPriceSelected={setPriceSelected}
              />
            </div>
          )}
        </div>
        <div className="gift-container__content__right">
          {isOpenBanner && (
            <ContactBanner close={() => setIsOpenBanner(false)} />
          )}

          <InfiniteScroll
            dataLength={
              isMobile ? ceil(products.length / 2) : ceil(products.length / 3)
            }
            next={() => fetchProducts(page + 1)}
            hasMore
          >
            <div className="gift-container__content__right__items">
              {map(products, (item, index) => (
                <ProductCard
                  key={index}
                  title={item?.title}
                  image={item?.img_url?.[0]}
                  merchant={item?.merchant}
                  price={item?.price}
                  className="product-card product-card--large"
                  onClick={() => navigateTo(`/gifts/${item?.slug}`)}
                />
              ))}
            </div>
          </InfiniteScroll>
          <div className="gift-container__content__loader">
            {(loading || nextLoading) && (
              <Loader
                type="ThreeDots"
                color="#082A33"
                height={isMobile ? 50 : 100}
                width={isMobile ? 50 : 100}
                timeout={3000}
              />
            )}
          </div>
          {showGoUpBtn && (
            <div
              className="gift-container__content__right__go-up-btn"
              onClick={() =>
                window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
              }
            >
              <img src={arrowUp} alt="" />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gifts;
