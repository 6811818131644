/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { map } from 'lodash';
import Anniversary from './Anniversary';
import Birthday from './BirthDate';
import CustomDay from './CustomDay';
import Christmas from './Christmas';
import { useProduct } from '../../context/ProductContext';
import './index.scss';

const UpComingDates = ({ events }) => {
  const { segments } = useProduct();
  const renderEvent = (key, events) => {
    switch (key) {
      case 'Birthday':
        return <Birthday events={events[key]} segments={segments} />;
      case 'Anniversary':
        return <Anniversary events={events[key]} segments={segments} />;
      case 'Christmas':
        return <Christmas events={events[key]} segments={segments} />;
      default:
        return <CustomDay events={events[key]} segments={segments} />;
    }
  };

  return (
    <div className="upcoming">
      {Object.keys(events).length === 0 && (
        <span className="upcoming__no-events">
          There are no upcoming events
        </span>
      )}
      {events &&
        map(Object.keys(events), (date, index) => (
          <div className="upcoming__date" key={`date-events-${index}`}>
            <span className="upcoming__date__title">
              {date && moment(date).format('MMM Do')}
            </span>
            <div className="upcoming__date__events">
              {map(Object.keys(events[date]), (ev, key) => (
                <div
                  className="upcoming__date__events__event"
                  key={`evnets-${ev}-${key}`}
                >
                  {renderEvent(ev, events[date])}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

UpComingDates.propTypes = {
  events: PropTypes.object,
};

export default UpComingDates;
