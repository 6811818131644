import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import productExampleGreenBanner from '../../assets/images/banner/productExampleGreenBanner.svg';
import CategoryCard from './CategoryCard';
import { colors, getRandomOfArray } from '../../helpers/utils';
import { navigateTo } from '../../helpers/historyHelper';
import './index.scss';

const PopularCategories = ({ categories }) => (
  <div className="popular-categories">
    <div className="popular-categories__text">
      <span className="popular-categories__text__title">
        Popular Categories
      </span>
      <a
        className="popular-categories__text__link"
        onClick={() => navigateTo('/gifts')}
      >
        See More
      </a>
    </div>
    <div className="popular-categories__list">
      {map(categories, (item, index) => (
        <CategoryCard
          key={`popular-category-${index}`}
          title={item.name}
          image={productExampleGreenBanner}
          color={getRandomOfArray(colors)}
          handleSelectCategory={() => {
            navigateTo('/gifts', { categorySelected: item });
          }}
        />
      ))}
    </div>
  </div>
);

PopularCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
};

export default PopularCategories;
