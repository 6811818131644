import React from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/Button';
import importantPeople from '../../assets/images/importantPeople/importantPeople.svg';
import importantPeopleMobile from '../../assets/images/importantPeople/importantPeopleMobile.svg';
import './index.scss';
import cross from '../../assets/icons/cross.svg';

const ImportantPeopleSection = ({ handleAddContact, handleClose }) => (
  <div className="container">
    <div className="container__important-people">
      <div className="container__important-people__important-people-section">
        <div className="container__important-people__important-people-section__left">
          <span className="container__important-people__important-people-section__left__text">
            Important people in life come with important dates.
          </span>
          <span className="container__important-people__important-people-section__left__small-text">
            Add reminders to keep track of all important dates in your life
          </span>
          <Button
            className="btn--medium-large btn--primary"
            onClick={handleAddContact}
          >
            ADD REMINDERS
          </Button>
        </div>
        <div className="container__important-people__important-people-section__right">
          <img
            className="container__important-people__important-people-section__right__image"
            alt=""
            src={importantPeople}
          />
          <img
            className="container__important-people__important-people-section__right__image-mobile"
            alt=""
            src={importantPeopleMobile}
          />
        </div>
      </div>
    </div>
    <div className="container__close">
      <Button className="container__close__btn" onClick={handleClose}>
        <img alt="" src={cross} className="container__close__img" />
      </Button>
    </div>
  </div>
);

ImportantPeopleSection.propTypes = {
  handleAddContact: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ImportantPeopleSection;
