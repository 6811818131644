/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { func } from 'prop-types';
import { useUser } from '../../context/UserContext';
import AddContactModal from '../AddContactModal';
import brownStar from '../../assets/icons/brownStar.svg';
import cross from '../../assets/icons/cross.svg';

import './index.scss';

const ContactBanner = ({ close }) => {
  const { showAddContactModal, setShowAddContactModal } = useUser();

  return (
    <div className="contact-banner">
      <img
        className="contact-banner__close"
        src={cross}
        alt=""
        onClick={close}
      />
      {showAddContactModal && (
        <AddContactModal close={() => setShowAddContactModal(false)} />
      )}
      <div className="contact-banner__left">
        <span className="contact-banner__left__title">
          Shopping for someone special?
        </span>
        <span className="contact-banner__left__subtitle">
          Add their contact to get date reminders and gifts recommendations
        </span>
      </div>
      <div className="contact-banner__right">
        <img className="contact-banner__right__star" src={brownStar} alt="" />
        <button
          className="contact-banner__right__button"
          type="button"
          onClick={() => setShowAddContactModal(true)}
        >
          ADD CONTACT
        </button>
      </div>
    </div>
  );
};

ContactBanner.propTypes = {
  close: func.isRequired,
};

export default ContactBanner;
