import React from 'react';
import { number, func } from 'prop-types';
import TabButton from '../TabButton';
import './index.scss';

const ProfileTabs = ({ selectedTab, setSelectedTab }) => (
  <div className="profile-tabs">
    <TabButton
      onClick={() => setSelectedTab(0)}
      isSelected={selectedTab === 0}
      text="Upcoming dates"
    />
    <TabButton
      onClick={() => setSelectedTab(1)}
      isSelected={selectedTab === 1}
      text="All contacts"
    />
  </div>
);

ProfileTabs.propTypes = {
  selectedTab: number.isRequired,
  setSelectedTab: func.isRequired,
};

export default ProfileTabs;
