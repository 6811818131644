/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { map, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { goBack, navigateTo } from '../../helpers/historyHelper';
import ExpandedHeader from '../../components/ExpandedHeader';
import Footer from '../../components/Footer';
import RecommendedProductList from '../../components/RecommendedProductList';
import Button from '../../components/shared/Button';
import DetailImage from '../../components/DetailImage';
import './index.scss';
import SmallBadge from '../../components/SmallBadge';
import GiftForModal from '../../components/GiftForModal';
import { useUser } from '../../context/UserContext';
import { useProduct } from '../../context/ProductContext';

const GiftDetail = () => {
  const { user, allContacts } = useUser();
  const { getProductBySlug } = useProduct();
  const params = useParams();
  const [product, setProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);

  const [isGiftModalOpen, setIsGiftModalOpen] = useState(false);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (params && params.slug) {
      getProductBySlug(params.slug).then((response) => {
        setProduct(response?.product || {});
        setSimilarProducts(response?.similar_products || []);
      });
    }
  }, [params]);

  useEffect(() => {
    if (search?.length > 2) {
      navigateTo('/gifts', { search });
    }
  }, [search]);

  const handlePurchaseNoRecipient = () => {
    window && user && window.trackEvent('Press Get Gift', user.email);
    const newWindow = window.open(
      `/redirect/${product?.slug}`,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  const handleGetGift = () => {
    if (allContacts?.length > 0) {
      setIsGiftModalOpen(true);
    } else {
      handlePurchaseNoRecipient();
    }
  };

  const handlePurchaseWithRecipient = () => {
    // TODO: Behavior missing
    handlePurchaseNoRecipient();
  };

  return (
    <div className="gift-detail">
      <ExpandedHeader search={search} setSearch={setSearch} />
      {isGiftModalOpen && (
        <GiftForModal
          contacts={allContacts}
          close={() => setIsGiftModalOpen(false)}
          handleSubmitNoRecipient={handlePurchaseNoRecipient}
          handleSubmitWithRecipient={handlePurchaseWithRecipient}
        />
      )}
      <button type="button" className="gift-detail__back-btn" onClick={goBack}>
        Back
      </button>
      <div className="gift-detail__container">
        <DetailImage images={product?.img_url} />

        <div className="gift-detail__container__right">
          <span className="gift-detail__container__right__title">
            {product?.title}
          </span>
          <span className="gift-detail__container__right__price">
            {product?.price && `$${product?.price}`}
          </span>
          <div className="gift-detail__container__right__badges">
            {map(product?.missions, (badge) => (
              <SmallBadge
                key={badge?.id}
                image={badge?.picture}
                name={badge?.name}
              />
            ))}
          </div>
          <span className="gift-detail__container__right__description">
            {product?.description}
          </span>
          {product?.merchant && (
            <>
              <span className="gift-detail__container__right__merchant-title">
                Merchant
              </span>
              <a
                target="_blank"
                rel="noreferrer"
                href={product?.merchant?.link_url}
                className="gift-detail__container__right__merchant"
              >
                {product?.merchant?.name}
              </a>
            </>
          )}
          <Button
            disabled={isEmpty(product)}
            onClick={handleGetGift}
            className="btn--primary btn--x-large gift-detail__container__right__get-btn"
          >
            GET THIS GIFT
          </Button>
        </div>
      </div>
      {!isEmpty(similarProducts) && (
        <RecommendedProductList
          products={similarProducts}
          listTitle="Similar Products"
          productsNumber={4}
        />
      )}
      <Footer />
    </div>
  );
};

export default GiftDetail;
