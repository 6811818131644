/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import { map, keys } from 'lodash';
import { func, object } from 'prop-types';
import { navigateTo } from '../../../helpers/historyHelper';
import { pronountToSegment } from '../../../helpers/parsers';
import cake from '../../../assets/icons/cake.svg';
import heart from '../../../assets/icons/heart.svg';
import tree from '../../../assets/icons/tree.svg';
import { useProduct } from '../../../context/ProductContext';

import './index.scss';

const NotificationDropdown = ({ close, bellRef, notifications }) => {
  const ref = useRef();
  const { segments } = useProduct();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !bellRef.current.contains(event.target)
      ) {
        close();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const getIconFromEvent = (event) => {
    switch (event) {
      case 'Christmas':
        return tree;
      case 'Anniversary':
        return heart;
      case 'Birthday':
        return cake;
      default:
        return null;
    }
  };

  return (
    <div ref={ref} className="notification-dropdown">
      <span className="notification-dropdown__title">Notifications</span>
      {keys(notifications).length === 0 && (
        <span className="notification-dropdown__empty-notifications">
          No new notifications.
        </span>
      )}
      {map(keys(notifications), (date, index) => (
        <div className="notification-dropdown__container" key={index}>
          <span className="notification-dropdown__container__date">
            {moment(`${date}, ${moment().get('year')}`)
              .startOf('day')
              .fromNow()}
          </span>
          <div className="notification-dropdown__container__list">
            {map(keys(notifications[date]), (event, key) => (
              <div key={key}>
                {map(notifications[date][event], (user, key) => (
                  <div
                    key={`user-${key}`}
                    className="notification-dropdown__container__list__event"
                  >
                    <div className="notification-dropdown__container__list__event__container">
                      {getIconFromEvent(user.date_name) && (
                        <img src={getIconFromEvent(user.date_name)} alt="" />
                      )}
                      <span>
                        {moment(user.date).format('Do MMM')}&nbsp;is&nbsp;
                      </span>
                      <span className="notification-dropdown__container__list__event__bold">
                        {user.contact_name}&apos;s&nbsp;
                      </span>
                      <span>{user.date_name}</span>
                    </div>
                    <span
                      className="notification-dropdown__container__list__event__link"
                      onClick={() => {
                        close();
                        navigateTo('/gifts', {
                          segmentSelected: pronountToSegment(
                            segments,
                            user.contact_pronoun
                          ),
                        });
                      }}
                    >
                      Explore gift ideas
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

NotificationDropdown.propTypes = {
  close: func.isRequired,
  bellRef: object.isRequired,
  notifications: object,
};

export default NotificationDropdown;
