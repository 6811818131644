import React, { useState } from 'react';
import { map, slice } from 'lodash';
import PropTypes from 'prop-types';
import leftArrow from '../../assets/icons/leftWhiteArrow.svg';
import './index.scss';

const DetailImage = ({ images }) => {
  const [x, setX] = useState(0);

  const goLeft = () => {
    setX(x - 1);
  };

  const goRight = () => {
    setX(x + 1);
  };

  return (
    <div className="detail-image">
      <div className="detail-image__photo">
        {map(images, (item, index) => (
          <div
            className="detail-image__photo__container"
            key={index}
            style={{ transform: `translateX(${x * -100}%)` }}
          >
            <img src={item} alt="" />
          </div>
        ))}
        {images?.length > 1 && (
          <div className="detail-image__photo__count-container">
            <span>{`${x + 1} of ${images.length}`}</span>
          </div>
        )}
        {x > 0 && (
          <div
            className="detail-image__photo__arrow detail-image__photo__arrow--left"
            onClick={goLeft}
          >
            <img src={leftArrow} alt="left arrow" />
          </div>
        )}
        {x < images?.length - 1 && (
          <div
            className="detail-image__photo__arrow detail-image__photo__arrow--right"
            onClick={goRight}
          >
            <img src={leftArrow} alt="left arrow" />
          </div>
        )}
      </div>
      {images?.length > 1 && (
        <div className="detail-image__small-images">
          {map(slice(images, 0, 4), (img, index) => (
            <div
              key={index}
              onClick={() => setX(index)}
              className="detail-image__small-images__container"
            >
              <img src={img} alt="" />
            </div>
          ))}
          {images?.length > 4 && (
            <div className="detail-image__small-images__plus">
              <span>+{images?.length - 4}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DetailImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

export default DetailImage;
