/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, keys, map } from 'lodash';
import heartImageVector from '../../assets/images/heartImageVector.svg';
import noNotifications from '../../assets/icons/noNotifications.svg';
import { navigateTo } from '../../helpers/historyHelper';
import './index.scss';

const EventReminder = ({ upcomingEvents }) => {
  const [date, setDate] = useState('');
  const [events, setEvents] = useState([]);

  const buildEvents = (eventsNames, events) => {
    const message = map(eventsNames, (ev) => {
      const peopleEv = map(events[ev], (person) => person.contact_name).join(
        ', '
      );
      return `${peopleEv}'s ${ev}`;
    });
    setEvents(message);
  };
  useEffect(() => {
    if (!isEmpty(upcomingEvents)) {
      const date = keys(upcomingEvents)[0];
      setDate(date);
      const events = upcomingEvents[date];
      const eventsNames = keys(upcomingEvents[date]);
      buildEvents(eventsNames, events);
    }
  }, [upcomingEvents]);

  return (
    <div className="event-reminder">
      <div>
        <img className="event-reminder__left" src={heartImageVector} alt="" />
      </div>
      <div className="event-reminder__right">
        <img
          className="event-reminder__right__bell"
          src={noNotifications}
          alt=""
        />
        <div className="event-reminder__right__information">
          <span className="event-reminder__right__information__title">
            {events.length === 1
              ? 'You have an event soon!'
              : 'You have events soon!'}
          </span>
          {map(events, (ev, index) => (
            <span
              className="event-reminder__right__information__event"
              key={`event-${index}`}
            >
              {ev}
            </span>
          ))}
          <span className="event-reminder__right__information__date">
            {`It${events.length === 1 ? `'s` : ' are'} on `}
            <b>{date}</b>
          </span>
          <button
            className="event-reminder__right__information__link"
            type="button"
            onClick={() => navigateTo('/profile')}
          >
            See all upcoming dates
          </button>
        </div>
      </div>
    </div>
  );
};

EventReminder.propTypes = {
  upcomingEvents: PropTypes.object,
};

export default EventReminder;
