import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import tree from '../../../assets/icons/tree.svg';
import { navigateTo } from '../../../helpers/historyHelper';
import { pronountToSegment } from '../../../helpers/parsers';
import './index.scss';

const Christmas = ({ events, segments }) => (
  <div className="christmas">
    <div className="christmas__name-container">
      <img src={tree} alt="" />
      <span className="christmas__name-container__name">
        &nbsp;Christmas gifts for
      </span>
    </div>
    <div className="christmas__user-container">
      {map(events, (item, index) => (
        <div
          key={`christmas-${index}`}
          className="christmas__user-container__user"
        >
          <span className="christmas__user-container__user__name">
            {item.contact_name}
          </span>
          <button
            className="christmas__button"
            type="button"
            onClick={() =>
              navigateTo('/gifts', {
                segmentSelected: pronountToSegment(
                  segments,
                  item.contact_pronoun
                ),
              })
            }
          >
            Explore Gifts
          </button>
        </div>
      ))}
    </div>
  </div>
);

Christmas.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
};
export default Christmas;
