/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { func } from 'prop-types';
import { validateEmail } from '../../helpers/validators';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

const ReferralModal = ({ close }) => {
  const [isMobile, setDesktop] = useState(window.innerWidth <= 768);
  const [shareUrl, setShareUrl] = useState('');
  const [step, setStep] = useState(1);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState('');

  const handleSendInvitation = () => {
    if (!validateEmail(emailAddress)) {
      setEmailError('Enter a valid email address');
      return;
    }
    try {
      // eslint-disable-next-line no-undef
      prefinery('addUser', emailAddress, (user) => {
        setShareUrl(user?.referral_link);
        setStep(2);
      });
    } catch (_) {
      setError('Cant send invitation, try again later');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <ReactModal
      ariaHideApp={false}
      className="modal"
      overlayClassName="overlay"
      isOpen
      style={{ content: { top: isMobile ? '20vw' : '5vw' } }}
    >
      {step === 1 && (
        <FirstStep
          close={close}
          emailAddress={emailAddress}
          emailError={emailError}
          error={error}
          handleSendInvitation={handleSendInvitation}
          setEmailAddress={setEmailAddress}
        />
      )}
      {step === 2 && <SecondStep close={close} error={error} url={shareUrl} />}
    </ReactModal>
  );
};

ReferralModal.propTypes = {
  close: func.isRequired,
};

export default ReferralModal;
